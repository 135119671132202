/* @flow */
import * as React from "react";
// $FlowFixMe
import { Droppable, Draggable } from "react-beautiful-dnd";
import AutoScrollContainer from "./AutoScrollContainer";
import Image from "./Image";
import Video from "./Video";
import PendingMedia from "./PendingMedia";
import LoadingIcon from "icons/LoadingIcon";
import styles from "./index.css";

import TextInput from "forms/TextInput";
import type { MediaUpload } from "types";
import { CloseButton } from "buttons";

// $FlowFixMe
import { DragDropContext } from "react-beautiful-dnd";

export type ListItem = {
  src: string,
  id?: string,
  alt?: string,
  order?: string,
  renderOverlay?: () => React.Node
};

type Props = {
  caption?: React.Node,
  images: ListItem[],
  imagesTextVariations?: any[],
  videos: ListItem[],
  pendingMedia?: MediaUpload[],
  loading?: boolean,
  scrollToNewMedia?: boolean,
  onRemoveImage?: (id: string) => void,
  onRemoveVideo?: (id: string) => void,
  onEditImage?: (oldImageId?: string, cropData: any) => void,
  updatingImage: boolean,
  onDragEnd: (result: any, provided: any) => void,
  showLinkedInCarouselProps?: boolean,
  handleUpdateTitleImage?: (oldImageId?: string, textData: string) => void
};

const MediaList = ({
  images,
  imagesTextVariations,
  videos,
  pendingMedia = [],
  caption,
  loading = false,
  scrollToNewMedia = true,
  onRemoveImage,
  onRemoveVideo,
  onEditImage,
  updatingImage,
  onDragEnd,
  showLinkedInCarouselProps,
  handleUpdateTitleImage,
  onClose,
  instaStory
}: Props) => {
  return (
    <div>
      {caption && (
        <>
          <div className={styles.caption}>{caption}</div>
          <CloseButton onClick={onClose} />
        </>
      )}
      <DragDropContext
        onDragStart={() => {}}
        onDragUpdate={() => {}}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="media-list" direction={showLinkedInCarouselProps ? "vertical" : "horizontal"}>
          {provided => (
            <div
              className={styles.listContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {loading && (
                <div className={styles.overlay}>
                  <LoadingIcon className={styles.loadingIcon} />
                </div>
              )}
              <AutoScrollContainer
                showLinkedInCarouselProps={showLinkedInCarouselProps}
                disableAutoScroll={!scrollToNewMedia}
              >
                {videos.map((item: ListItem) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Video
                      key={item.src}
                      src={item.src}
                      instaStory={instaStory}
                      overlay={item.renderOverlay ? item.renderOverlay() : null}
                      onRemove={
                        onRemoveVideo ? onRemoveVideo.bind(null, item.id) : null
                      }
                    />
                  </div>
                ))}
                {images.map((item: ListItem, index) => {
                  function getAlternateText(imageId) {
                    return (
                      imagesTextVariations?.find(x => x.imageId == imageId)
                        ?.text || ""
                    );
                  }
                  if (item.id && images.length > 1) {
                    return (
                      <div
                        className={
                          showLinkedInCarouselProps
                            ? styles.showLinkedInCarouselProps
                            : ""
                        }
                      >
                        <Draggable
                          draggableId={item.id}
                          index={item.order}
                          key={item.id}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Image
                                src={item.src}
                                alt={item.alt}
                                key={item.src}
                                instaStory={instaStory}
                                overlay={
                                  item.renderOverlay
                                    ? item.renderOverlay()
                                    : null
                                }
                                onRemove={
                                  onRemoveImage
                                    ? onRemoveImage.bind(null, item.id)
                                    : null
                                }
                                onEdit={cropData =>
                                  onEditImage
                                    ? onEditImage(item.id, cropData)
                                    : null
                                }
                                updatingImage={updatingImage}
                              />
                              {showLinkedInCarouselProps ? (
                                <TextInput
                                  id={`variation-media-${item.id}`}
                                  value={item.text || getAlternateText(item.id)}
                                  clear
                                  onChange={text =>
                                    handleUpdateTitleImage(item.id, text)
                                  }
                                  onBlur={() => {}}
                                />
                              ) : null}
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className={
                          showLinkedInCarouselProps
                            ? styles.showLinkedInCarouselOneProps
                            : ""
                        }
                      >
                        <Image
                          src={item.src}
                          alt={item.alt}
                          key={item.src}
                          instaStory={instaStory}
                          overlay={
                            item.renderOverlay ? item.renderOverlay() : null
                          }
                          onRemove={
                            onRemoveImage
                              ? onRemoveImage.bind(null, item.id)
                              : null
                          }
                          onEdit={(cropData: any) =>
                            onEditImage ? onEditImage(item.id, cropData) : null
                          }
                          updatingImage={updatingImage}
                        />
                        {showLinkedInCarouselProps ? (
                          <TextInput
                            id={`variation-media-${item.id}`}
                            value={item.text || getAlternateText(item.id)}
                            clear
                            onChange={text =>
                              handleUpdateTitleImage(item.id, text)
                            }
                            onBlur={() => {}}
                          />
                        ) : null}
                      </div>
                    );
                  }
                })}
                {pendingMedia.map(({ progress, fileName }) => (
                  <PendingMedia key={fileName} progress={progress || 0} />
                ))}
                {provided.placeholder}
              </AutoScrollContainer>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default MediaList;

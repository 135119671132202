/* @flow */
import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location } from "react-router-dom";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import pluralize from "pluralize";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";
import Moment from "moment";

import NewHeader from "components/NewHeader";
import { createFlash } from "actions/flash";
import App from "components/App";
import PageTitle from "links/PageTitle";
import Button from "buttons/Button";
import LoadingIcon from "icons/LoadingIcon";
import Link from "links/Link";
import Message from "layout/Message";
import EmptyState from "./EmptyState";
import ScheduleInformation from "./ScheduleInformation";
import ScheduleModal from "./ScheduleModal";
import OldScheduleModal from "./OldScheduleModal";
import Confirmation from "layout/modals/Confirmation";
import UpgradeModal from "components/UpgradeModal";
import Calendar from "./Calendar";
import NewCalendar from './NewCalendar';
import FilterSelect from "components/common/FilterSelect";
import AccountBadge from "icons/AccountBadge";
import ErrorWithRetry from "components/ErrorWithRetry";
import withFilter, { parseFilterParams } from "behavioral/withFilter";
import { colorFromIndex } from "colors";
import { useNewUICached, featureFlag } from "util";
import ScheduleNewModal from './ScheduleNewModal';
// import ScheduleViewToggle from "components/ScheduleViewToggle";

import GET_NEW_UI from "queries/getNewUI";
import GET_SCHEDULE from "queries/getSchedule";
import GET_SCHEDULE_V2 from "queries/getScheduleV2";
import GET_TIMESLOTS from "queries/getTimeslots";
import CREATE_SCHEDULE from "mutations/createSchedule";
import CREATE_SCHEDULE_V2 from "mutations/createScheduleV2";
import UPDATE_SCHEDULE from "mutations/updateSchedule";
import UPDATE_SCHEDULE_V2 from "mutations/updateScheduleV2";
import UPDATE_SCHEDULE_V4 from "mutations/updateScheduleV4";
import DELETE_SCHEDULE from "mutations/deleteSchedule";
import DELETE_SCHEDULE_V4 from "mutations/deleteScheduleV4";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import DISMISS_SCHEDULE_SUGGESTION from "mutations/dismissScheduleSuggestion";
import GET_SCHEDULE_POSTS from "queries/getSchedulePosts";

import styles from "./index.css";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  ProductName,
  getSchedule_accounts as AccountData,
  getSchedule_schedules as ScheduleData,
  getSchedule_scheduleSuggestions as ScheduleSuggestionData,
  getSchedule_user_onboardingProgress as OnboardingProgressData
} from "graphql-types/getSchedule";
import type {
  createScheduleVariables as CreateScheduleVariables,
  createSchedule as CreateScheduleResult
} from "graphql-types/createSchedule";
import type {
  updateScheduleVariables as UpdateScheduleVariables,
  updateSchedule as UpdateScheduleResult
} from "graphql-types/updateSchedule";
import type {
  deleteScheduleVariables as DeleteScheduleVariables,
  deleteSchedule as DeleteScheduleResult
} from "graphql-types/deleteSchedule";
import type {
  dismissScheduleSuggestionVariables as DismissScheduleSuggestionVariables,
  dismissScheduleSuggestion as DismissScheduleSuggestionResult
} from "graphql-types/dismissScheduleSuggestion";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import { type CategoryOption } from "components/common/CategorySelect";
import type { ContentFilter } from "types";

import { connect } from "react-redux";
import type { Dispatch } from "types";
import { WeekSelect, LeftIcon, RightIcon, WeekCenter } from "./styles.js";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

type OwnProps = {
  location: Location,
  match: Match
} & TopbarProps;

type TopbarProps = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  onboardingProgress: OnboardingProgressData,
  dismissOnboarding: () => Promise<DismissOnboardingResult>
};

type Mutation<V, R> = ({ variables: V }) => Promise<R>;

type Props = {
  product: ProductName,
  accounts: AccountData[],
  categories: CategoryOption[],
  schedules: ScheduleData[],
  scheduleLimitReached: boolean,
  scheduleSuggestions: ScheduleSuggestionData[],
  totalApprovedContents: number,
  timezone: string,
  filter: ContentFilter,
  createSchedule: Mutation<CreateScheduleVariables, CreateScheduleResult>,
  updateSchedule: Mutation<UpdateScheduleVariables, UpdateScheduleResult>,
  deleteSchedule: Mutation<DeleteScheduleVariables, DeleteScheduleResult>,
  dismissScheduleSuggestion: Mutation<
    DismissScheduleSuggestionVariables,
    DismissScheduleSuggestionResult
  >,
  fetching: boolean,
  creating: boolean,
  deleting: boolean,
  updating: boolean,
  dismissingScheduleSuggestion: boolean,
  paused: boolean,
  createdScheduleIds: string[],
  mutationError: ?string,
  error: ?string,
  createErrors: string[],
  onClearCreateErrors: () => void,
  startDate?: Moment,
  dispatch?: Dispatch,
  setErrorToDisplay?: (value: string | null) => void
} & TopbarProps;

const Schedule = ({
  topNav,
  subscriptionStatus,
  onboardingProgress,
  timezone,
  paused,
  product,
  filter,
  accounts,
  categories,
  schedules,
  scheduleLimitReached,
  scheduleSuggestions,
  createdScheduleIds,
  totalApprovedContents,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  dismissOnboarding,
  dismissScheduleSuggestion,
  fetching,
  creating,
  deleting,
  updating,
  dismissingScheduleSuggestion,
  createErrors,
  onClearCreateErrors,
  error,
  mutationError,
  startDate,
  dispatch,
  setErrorToDisplay,
  loadingPosts
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [showAddSchedule, setShowAddSchedule] = useState(null);
  const [showLimitReached, setShowLimitReached] = useState(false);
  const [useNewUI, setUseNewUI] = useState(useNewUICached());
  const apolloClient = useApolloClient();


  const redirectToOldSchedule = () => {
    setLoading(true);
    window.location.href = "/schedule_old";
  };

  useEffect(() => {
    const fetchGetNewUI = async () => {
      if (featureFlag("force_new_ui")) {
        setUseNewUI(true);
        localStorage.setItem("navbar_modules_ui", "true");
      } else if (!featureFlag("navbar_modules_ui")) {
        setUseNewUI(false);
        localStorage.setItem("navbar_modules_ui", "false");
      } else {
        const dataFromLocal = localStorage.getItem("navbar_modules_ui");
        if (dataFromLocal && dataFromLocal === "true") setUseNewUI(true);

        try {
          const res = await apolloClient.query({
            query: GET_NEW_UI,
            fetchPolicy: "no-cache"
          });

          setUseNewUI(get(res, "data.getNewUI.enabled", false));
          localStorage.setItem(
            "navbar_modules_ui",
            get(res, "data.getNewUI.enabled", false).toString()
          );
        } catch (e) {
          // console.log(e);
        }
      }
    };

    fetchGetNewUI();
  }, [apolloClient]);

  const handleSubmit = useCallback(
    async (input, type) => {
      setErrorToDisplay(null);

      try {
        if (!input.id) {
          await createSchedule({ variables: { input } });
          setShowAddSchedule(null);
        } else if (type) {
          await updateSchedule({ variables: { input: input, type: type } });
          setShowAddSchedule(null);
        }
        else {
          await updateSchedule({ variables: { input } });
          setShowAddSchedule(null);
        }
      } catch (e) {
        setErrorToDisplay(e.message);
      }
    },
    [setErrorToDisplay, createSchedule, updateSchedule]
  );

  const handleDelete = useCallback(
    async (scheduleId, selectedOption) => {
      if (selectedOption) {
        await deleteSchedule({
          variables: { id: scheduleId, type: selectedOption }
        });
      }
      else {

        await deleteSchedule({
          variables: { id: scheduleId }
        });
      }
      setShowAddSchedule(null);
    },
    [deleteSchedule]
  );

  const handleDismissScheduleSuggestion = useCallback(
    async (offset, platforms) => {
      await dismissScheduleSuggestion({
        variables: { offset, platforms }
      });
      setShowAddSchedule(null);
    },
    [dismissScheduleSuggestion]
  );

  const handleClickEditTimeslot = useCallback(calendarItem => {
    setShowAddSchedule(calendarItem);
  }, []);

  const handleClickNewTimeslot = useCallback(
    (day, hour, minutes) => {
      const startAt = !day
        ? moment().clone()
        : (startDate
          ? startDate.clone().startOf("week")
          : moment().startOf("week")
        )
          .set({ hour, minute: minutes })
          .add(daysOfWeek.indexOf(day), "days");

      // if (startAt.isBefore(moment()) && featureFlag("schedule_v2")) {
      //   dispatch(createFlash("alert", "You can't schedule for past dates."));
      //   return;
      // }

      if (scheduleLimitReached) {
        setShowLimitReached(true);
        return;
      }

      const sendAt = moment
        .utc()
        .set({ hour, minutes })
        .format("h:mm a");
      setShowAddSchedule({
        __typename: "Schedule",
        sendOn: day,
        sendAt,
        startAt: startAt.format("YYYY-MM-DD"),
        accounts: [],
        category: null
      });
    },
    [startDate, scheduleLimitReached]
  );

  const handleCloseLimitReachedModal = () => {
    onClearCreateErrors();
    setShowLimitReached(false);
  };

  const accountOptions = sortBy(accounts, ["provider", "name", "nickname"]).map(
    a => ({
      value: a.id,
      label: a.name || (a.nickname ? `@${a.nickname}` : null),
      icon: <AccountBadge value={a} />
    })
  );

  const RANDOM_CATEGORY_OPTION = {
    value: "0",
    label: "Random",
    color: "var(--inky400)"
  };

  const categoryOptions = [RANDOM_CATEGORY_OPTION].concat(
    sortBy(categories, ["name"]).map(c => ({
      value: c.id,
      label: c.name,
      color: colorFromIndex(c.colorIndex)
    }))
  );

  const displayCalendar = accounts.length > 0 && totalApprovedContents > 0;
  const timezoneAbbr = timezone && moment.tz.zone(timezone).abbr(moment());

  const currentWeek = startDate
    ? startDate.clone().startOf("week")
    : moment().startOf("week");

  const handleBackWeek = () => {
    const previousWeek = currentWeek
      .clone()
      .startOf("week")
      .subtract(7, "days");
    window.location.href = "/schedule/" + previousWeek.format("YYYY-MM-DD");
  };

  const handleForwardWeek = () => {
    const nextWeek = currentWeek
      .clone()
      .startOf("week")
      .add(7, "days");

    if (nextWeek.diff(moment().startOf("week"), "days") <= 14) {
      window.location.href = "/schedule/" + nextWeek.format("YYYY-MM-DD");
    } else {
      dispatch(
        createFlash("alert", "You can only view the next 14 days of schedules.")
      );
    }
  };

  return (
    <App
      loggedIn
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      newWidth
      header={
        <>
          <div className={styles.header}>
            {useNewUI && (
              <NewHeader
                title="Schedule"
                mb
                // titleContent={<ScheduleViewToggle />}
                {...topNav}
              />
            )}
            <div className={styles.toolbar}>
              <PageTitle
                className={styles.pageTitle}
                subtitle={timezoneAbbr && `All times in ${timezoneAbbr}`}
              >
                Recurring automations
              </PageTitle>
              {displayCalendar ? (
                <>
                  <span className={styles.filterByLabel}>Filter by</span>
                  <FilterSelect
                    className={styles.categoryFilter}
                    options={categoryOptions}
                    placeholder="All categories"
                    maxMenuWidth="200%"
                    filterKey="category"
                  />
                  <FilterSelect
                    className={styles.accountFilter}
                    options={accountOptions}
                    placeholder="All accounts"
                    maxMenuWidth="200%"
                    filterKey="account"
                  />
                  <Button
                    className={styles.addTimeslotButton}
                    onClick={handleClickNewTimeslot.bind(null, null, 12)}
                    type="primary"
                  >
                    Add time slot
                  </Button>
                </>
              ) : null}
            </div>
            <div className={styles.timeslotsCount}>
              {schedules.length} {pluralize("time slot", schedules.length)}{" "}
              {!!Object.keys(filter).length && "showing"}
            </div>
          </div>

          {(featureFlag("schedule_v2") || featureFlag("schedule_v4")) && (
            <div className={styles.header}>
              <div className={styles.secondToolbar}>
                <PageTitle className={styles.pageTitle}>
                  {currentWeek.format("MMMM YYYY")}
                </PageTitle>
                <WeekSelect>
                  <LeftIcon onClick={handleBackWeek}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                      >
                        <g
                          fill="#202F39"
                          fillRule="nonzero"
                          transform="translate(-810 -128)"
                        >
                          <g transform="translate(810.094 128.28)">
                            <path d="M4.172 7.313l-2.86-3.187L4.172.938a.56.56 0 00.14-.375.624.624 0 00-.164-.422c-.296-.203-.57-.187-.82.047L.141 3.728c-.188.25-.188.5 0 .75L3.305 8.04c.25.234.515.25.797.047.25-.235.273-.492.07-.774zm.469-3.586c-.188.25-.188.5 0 .75L7.805 8.04c.25.234.515.25.797.047.234-.25.25-.516.046-.797L5.813 4.126 8.671.938a.56.56 0 00.14-.375.624.624 0 00-.164-.422c-.28-.203-.546-.187-.796.047L4.64 3.728z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </LeftIcon>
                  <WeekCenter today={!startDate} className={styles.weekCenter}>
                    {moment()
                      .startOf("week")
                      .isSame(currentWeek)
                      ? "TODAY"
                      : startDate
                        ? startDate.format("DD MMM, Y")
                        : "TODAY"}
                  </WeekCenter>
                  <RightIcon onClick={handleForwardWeek}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                      >
                        <g
                          fill="#202F39"
                          fillRule="nonzero"
                          transform="translate(-810 -128)"
                        >
                          <g transform="translate(810.094 128.28)">
                            <path d="M4.172 7.313l-2.86-3.187L4.172.938a.56.56 0 00.14-.375.624.624 0 00-.164-.422c-.296-.203-.57-.187-.82.047L.141 3.728c-.188.25-.188.5 0 .75L3.305 8.04c.25.234.515.25.797.047.25-.235.273-.492.07-.774zm.469-3.586c-.188.25-.188.5 0 .75L7.805 8.04c.25.234.515.25.797.047.234-.25.25-.516.046-.797L5.813 4.126 8.671.938a.56.56 0 00.14-.375.624.624 0 00-.164-.422c-.28-.203-.546-.187-.796.047L4.64 3.728z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </RightIcon>
                </WeekSelect>
              </div>
            </div>
          )}
        </>
      }
      messages={
        paused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting in{" "}
            <Link href="/queue">your Queue</Link>.
          </Message>
        ) : null
      }
    >
      {loading ? (
        <div className={styles.loading}>
          <LoadingIcon />
        </div>
      ) : (
        <>
          {scheduleSuggestions.length > 0 && displayCalendar ? (
            <div className={styles.scheduleInformationWrapper}>
              <ScheduleInformation />
            </div>
          ) : null}

          {(showAddSchedule && featureFlag("schedule_v4")) ?

            <ScheduleNewModal
              onClose={() => {
                setShowAddSchedule(null);
                setErrorToDisplay(null);
              }}
              accounts={accounts}
              categories={categories}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
              onDismissScheduleSuggestion={handleDismissScheduleSuggestion}
              calendarItem={showAddSchedule}
              creating={creating}
              deleting={deleting || dismissingScheduleSuggestion}
              updating={updating}
              error={mutationError}
              startAt={currentWeek.format("YYYY-MM-DD")}
            />
            : (showAddSchedule && featureFlag("schedule_v2")) ? (
              <ScheduleModal
                onClose={() => {
                  setShowAddSchedule(null);
                  setErrorToDisplay(null);
                }}
                accounts={accounts}
                categories={categories}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                onDismissScheduleSuggestion={handleDismissScheduleSuggestion}
                calendarItem={showAddSchedule}
                creating={creating}
                deleting={deleting || dismissingScheduleSuggestion}
                updating={updating}
                error={mutationError}
                startAt={currentWeek.format("YYYY-MM-DD")}
              />
            ) : showAddSchedule ? (
              <OldScheduleModal
                onClose={() => setShowAddSchedule(null)}
                accounts={accounts}
                categories={categories}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                onDismissScheduleSuggestion={handleDismissScheduleSuggestion}
                calendarItem={showAddSchedule}
                creating={creating}
                deleting={deleting || dismissingScheduleSuggestion}
                updating={updating}
                error={mutationError}
              />
            ) : null}

          {showLimitReached || createErrors.includes("limit_reached") ? (
            <LimitReachedModal
              product={product}
              onClose={handleCloseLimitReachedModal}
            />
          ) : null}

          {fetching || loadingPosts ? (
            <div className={styles.loadingContainer}>
              <LoadingIcon className={styles.loading} />
            </div>
          ) : error ? (
            <ErrorWithRetry>{error}</ErrorWithRetry>
          ) : accounts.length < 1 ? (
            <div className={styles.loadingContainer}>
              <EmptyState>
                <h3>You have no accounts!</h3>
                <p>
                  Connect a social media account before creating your schedule.
                </p>
                <Button type="primary" href="/accounts">
                  Add account
                </Button>
              </EmptyState>
            </div>
          ) : totalApprovedContents < 1 ? (
            <div className={styles.loadingContainer}>
              <EmptyState>
                <h3>Your library is empty!</h3>
                <p>Add content before creating your schedule.</p>
                <Button type="primary" href="/composer">
                  Add content
                </Button>
              </EmptyState>
            </div>
          ) : featureFlag("schedule_v4") ? (
            <NewCalendar
              timezone={timezone}
              schedules={schedules.concat(scheduleSuggestions)}
              focusedTimeslotIds={createdScheduleIds}
              onClickNewTimeslot={handleClickNewTimeslot}
              onClickEditTimeslot={handleClickEditTimeslot}
              redirectToOldSchedule={redirectToOldSchedule}
              currentWeek={currentWeek}
            />
          ) : (
            <Calendar
              timezone={timezone}
              schedules={schedules.concat(scheduleSuggestions)}
              focusedTimeslotIds={createdScheduleIds}
              onClickNewTimeslot={handleClickNewTimeslot}
              onClickEditTimeslot={handleClickEditTimeslot}
              redirectToOldSchedule={redirectToOldSchedule}
              currentWeek={currentWeek}
            />
          )}
        </>
      )}
    </App>
  );
};

const LimitReachedModal = ({ product, onClose }) => {
  return product === "edgar_lite" ? (
    <UpgradeModal
      name="timeSlotLimitReached"
      onClose={onClose}
      title="Upgrade now for more time slots!"
      subTitle="Your Eddie plan allows for 10 time slots. Need more? Upgrade to the Edgar plan and get 1000 time slots, plus 25 social media accounts and unlimited categories."
    />
  ) : (
    <Confirmation
      title="Upgrade now for more time slots!"
      subTitle="The maximum number of time slots that can be added to an Edgar account is 1,000. For more time slots, an additional Edgar account is required. Contact us about a discount for multiple Edgar accounts."
      type="error"
    >
      <div className={styles.upgradeLink}>
        <Link tabIndex="-1" href="mailto:support@meetedgar.com">
          Contact us to upgrade
        </Link>
      </div>
    </Confirmation>
  );
};

const ScheduleConnected = connect()(Schedule);

export default withFilter()(
  ({ match, location, topNav, subscriptionStatus }: OwnProps) => {
    const { filter } = parseFilterParams(match, location);
    let updatedData = [];

    // FIXME: `status` is set by default when parsing filters
    //        but status doesn't make sense here. `withFilter`
    //        and the filter parser should probably be a bit
    //        more general.
    delete filter.status;

    // Adding and validating start date from schedule revamp
    let startDate = undefined;
    let lastDate = undefined;
    if ((featureFlag("schedule_v2") || featureFlag("schedule_v4")) && location.pathname.search("schedule/")) {
      const locationSplit = location.pathname.split("schedule/");
      if (locationSplit.length > 1) {
        if (moment(locationSplit[1], "YYYY-MM-DD").isValid()) {
          const dateParam = moment(locationSplit[1], "YYYY-MM-DD").startOf(
            "week"
          );

          const daysDiff = dateParam
            .startOf("week")
            .diff(moment().startOf("week"), "days");
          if (daysDiff > 14) {
            window.location.href = "/schedule";
            return false;
          }

          startDate = dateParam.clone().startOf("week");
          if (featureFlag('schedule_v4')) {
            filter.startDate = dateParam.format("YYYY-MM-DDTHH:mm:ss.SSS");
            filter.lastDate = dateParam.add(7, "days").toISOString();
            lastDate = moment(filter?.lastDate).toDate();
          } else {
            filter.startDate = dateParam.toISOString();
            filter.lastDate = dateParam.add(7, "days").toISOString();
          }
        }
      }
    } else {
      const locationSplit = location.pathname.split("schedule/");
      if (locationSplit.length > 1) window.location.href = "/schedule";
    }

    function filterUniqueByIdAndScheduleId(dataArray) {
      const uniquePosts = new Map();

      return dataArray
        .filter(item => {
          if (item.__typename !== "Post") {
            return true;
          }

          const accountId = item.account?.id || null;

          if (uniquePosts.has(item.scheduleId)) {
            const existingPost = uniquePosts.get(item.scheduleId);

            const accountExists = existingPost.accounts.some(
              acc => acc.id === accountId
            );

            if (!accountExists && accountId) {
              existingPost.accounts.push(item.account);
            }

            return false;
          }

          const newPost = {
            ...item,
            accounts: [item.account]
          };

          uniquePosts.set(item.scheduleId, newPost);

          return true;
        })
        .map(item => {
          return uniquePosts.get(item.scheduleId) || item;
        });
    }

    const {
      loading: loadingPosts,
      error: postError,
      data: postData,
      refetch: refetchPosts
    } = useQuery(GET_SCHEDULE_POSTS, {
      variables: {
        filter
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true
    });


    // Queries
    const { loading: fetching, error, data, refetch } = useQuery(
      featureFlag("schedule_v4") ? GET_TIMESLOTS : featureFlag("schedule_v2") ? GET_SCHEDULE_V2 : GET_SCHEDULE,
      {
        variables: { filter },
        fetchPolicy: "network-only"
      }
    );

    if (startDate && lastDate && featureFlag("schedule_v4")) {
      const filteredData = data?.timeslots?.filter(timeslot => {
        let timeslotMoment = moment(timeslot.sendAt, "YYYY-MM-DD HH:mm");

        timeslotMoment = timeslotMoment.add(5, "hours");

        let timeslotDate = timeslotMoment.toDate();
        return true;
        return timeslotDate >= startDate && timeslotDate <= lastDate;
      });

      if (data && filteredData) {
        updatedData = { ...data, timeslots: filteredData };
      }
    } else {
      updatedData = Object.assign({}, data);
    }

    if (updatedData && postData?.schedulePosts?.length > 0) {
      if (updatedData?.timeslots?.length > 0) {
        updatedData.timeslots = [
          ...updatedData.timeslots,
          ...postData.schedulePosts
        ];
      } else {
        updatedData.timeslots = [...postData.schedulePosts];
      }

      updatedData.timeslots = filterUniqueByIdAndScheduleId(
        updatedData.timeslots
      );
    }

    // Mutations
    const [createSchedule, { loading: creating }] = useMutation(
      featureFlag("schedule_v2") || featureFlag("schedule_v4") ? CREATE_SCHEDULE_V2 : CREATE_SCHEDULE,
      {
        onCompleted(result) {
          const errors = featureFlag("schedule_v2") || featureFlag("schedule_v4")
            ? result.createScheduleV2.errors
            : result.createSchedule.errors;
          if (errors.length > 0) {
            setCreateErrors(errors);
            throw Error(errors[0]);
          }
        }
      }
    );
    const [updateSchedule, { loading: updating }] = useMutation(
      featureFlag("schedule_v4") ? UPDATE_SCHEDULE_V4 : featureFlag("schedule_v2") ? UPDATE_SCHEDULE_V2 : UPDATE_SCHEDULE,
      {
        onCompleted(result) {
          const errors = featureFlag("schedule_v4") ? result.updateScheduleV4.errors : featureFlag("schedule_v2")
            ? result.updateScheduleV2.errors
            : result.updateSchedule.errors;
          if (errors.length > 0) {
            setCreateErrors(errors);
            throw Error(errors[0]);
          }
        }
      }
    );
    const [
      deleteSchedule,
      { loading: deleting, error: deleteError }
    ] = useMutation(featureFlag("schedule_v4") ? DELETE_SCHEDULE_V4 : DELETE_SCHEDULE);
    const [
      dismissOnboarding,
      { loading: _dismissingOnboarding, error: _dismissOnboardingError }
    ] = useMutation(DISMISS_ONBOARDING);
    const [
      dismissScheduleSuggestion,
      { loading: dismissingScheduleSuggestion }
    ] = useMutation(DISMISS_SCHEDULE_SUGGESTION);

    // State
    const [focusedScheduleIds, setFocusedScheduleIds] = useState([]);
    const [createErrors, setCreateErrors] = useState([]);
    const [errorToDisplay, setErrorToDisplay] = useState();

    async function createScheduleAndRefetch(vars) {
      const result = await createSchedule(vars);
      await refetch();
      if (featureFlag("schedule_v4")) {
        await refetchPosts();
      }
      // After a schedule/timeslot is created add it to the focus list.
      // This is used to scroll to the timeslot and display an animation.
      // Then, after a few seconds remove the schedule/timeslot from the
      // focus list.
      setFocusedScheduleIds(
        (result?.data?.createSchedule?.schedules ?? []).map(s => s.id)
      );
      setTimeout(() => setFocusedScheduleIds([]), 3000);
      return result;
    }

    async function updateScheduleAndRefetch(vars) {
      const result = await updateSchedule(vars);
      await refetch();

      if (featureFlag("schedule_v4")) {
        await refetchPosts();
      }
      return result;
    }

    async function deleteScheduleAndRefetch(vars) {
      const result = await deleteSchedule(vars);
      await refetch();

      if (featureFlag("schedule_v4")) {
        await refetchPosts();
      }
      return result;
    }

    async function dismissScheduleSuggestionAndRefetch(vars) {
      const result = await dismissScheduleSuggestion(vars);
      await refetch();

      if (featureFlag("schedule_v4")) {
        await refetchPosts();
      }
      return result;
    }

    const handleClearCreateErrors = useCallback(() => setCreateErrors([]), [
      setCreateErrors
    ]);

    return (
      <>
        {featureFlag("schedule_v4") ? (
          <ScheduleConnected
            topNav={topNav}
            product={updatedData?.company?.subscriptionPlan?.productName}
            subscriptionStatus={subscriptionStatus}
            onboardingProgress={updatedData?.user?.onboardingProgress}
            timezone={updatedData?.company?.timeZone}
            dismissOnboarding={dismissOnboarding}
            dismissScheduleSuggestion={dismissScheduleSuggestionAndRefetch}
            paused={updatedData?.company?.queueStatus === "PAUSED"}
            schedules={
              featureFlag("schedule_v4")
                ? updatedData?.timeslots ?? []
                : updatedData?.schedules ?? []
            }
            scheduleLimitReached={!!updatedData?.company?.scheduleLimitReached}
            scheduleSuggestions={updatedData?.scheduleSuggestions ?? []}
            createdScheduleIds={focusedScheduleIds}
            filter={filter}
            accounts={updatedData?.accounts ?? []}
            categories={updatedData?.categories ?? []}
            totalApprovedContents={updatedData?.company?.totalApprovedContents ?? 0}
            createSchedule={createScheduleAndRefetch}
            updateSchedule={updateScheduleAndRefetch}
            deleteSchedule={deleteScheduleAndRefetch}
            fetching={fetching}
            creating={creating}
            updating={updating}
            deleting={deleting}
            dismissingScheduleSuggestion={dismissingScheduleSuggestion}
            createErrors={createErrors}
            onClearCreateErrors={handleClearCreateErrors}
            mutationError={errorToDisplay || deleteError}
            error={
              error || postError
                ? `Uh-oh something went wrong 😿 : ${error || postError}`
                : null
            }
            startDate={startDate}
            setErrorToDisplay={setErrorToDisplay}
            loadingPosts={loadingPosts}
          />
        ) : (
          <ScheduleConnected
            topNav={topNav}
            product={data?.company?.subscriptionPlan?.productName}
            subscriptionStatus={subscriptionStatus}
            onboardingProgress={data?.user?.onboardingProgress}
            timezone={data?.company?.timeZone}
            dismissOnboarding={dismissOnboarding}
            dismissScheduleSuggestion={dismissScheduleSuggestionAndRefetch}
            paused={data?.company?.queueStatus === "PAUSED"}
            schedules={
              featureFlag("schedule_v2")
                ? data?.schedulesV2 ?? []
                : data?.schedules ?? []
            }
            scheduleLimitReached={!!data?.company?.scheduleLimitReached}
            scheduleSuggestions={data?.scheduleSuggestions ?? []}
            createdScheduleIds={focusedScheduleIds}
            filter={filter}
            accounts={data?.accounts ?? []}
            categories={data?.categories ?? []}
            totalApprovedContents={data?.company?.totalApprovedContents ?? 0}
            createSchedule={createScheduleAndRefetch}
            updateSchedule={updateScheduleAndRefetch}
            deleteSchedule={deleteScheduleAndRefetch}
            fetching={fetching}
            creating={creating}
            updating={updating}
            deleting={deleting}
            dismissingScheduleSuggestion={dismissingScheduleSuggestion}
            createErrors={createErrors}
            onClearCreateErrors={handleClearCreateErrors}
            mutationError={errorToDisplay || deleteError}
            error={error ? `Uh-oh something went wrong 😿 : ${error}` : null}
            startDate={startDate}
            setErrorToDisplay={setErrorToDisplay}
          />
        )}
      </>
    );

  }
);
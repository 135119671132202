/* @flow */
import React, { useState } from "react";
import Icon from "icons/Icon";
import FilterSelect, {
  type Props as FilterProps
} from "components/common/FilterSelect";
import Label from "../Label";
import styles from "./index.css";

type Props = FilterProps & {
  label: string
};

export const Select = ({ toggle, label, ...rest }: Props) => {
  const [hide, setHide] =useState(toggle);
  const handleToggle =() =>{
    setHide(!hide)
  }
  const getToggleText = () => {
    if(hide){
      return <div> <Icon type="caret-down" /></div>
    }
    return <div><Icon type="caret-up" /></div>
  }
  return (
    <div className={styles.root}>
      <Label className={toggle ? styles.labelToggle : styles.label}>
        {label}
        {toggle && <div onClick={handleToggle}>
            {getToggleText()}
          </div>}
      </Label>
      <FilterSelect {...rest} className={hide ? styles.hidden : styles.select} />
    </div>
  );
};

export default Select;

// @flow
import * as React from "react";
import { featureFlag } from "util";

import { RemoveButton, EditButton } from "buttons";
import ScaledImage from "components/common/ScaledImage";

import useImage from "./use";
import styles from "./index.css";
import type { Props } from "./types.js";

import EditImageModal from "./EditImageModal";

const Image = (props: Props) => {
  const {
    src,
    alt,
    overlay,
    forwardedRef,
    onRemove,
    onEdit,
    updatingImage,
    format,
    instaStory
  } = props;
  const { handleRemove, modalVisible, showModal, hideModal } = useImage(props);

  return (
    <>
      <div className={instaStory ? styles.root : styles.root} ref={forwardedRef}>
        <ScaledImage value={src} alt={alt} />
        {!!overlay && <div className={styles.overlay}>{overlay}</div>}
        {!!onEdit && !overlay && featureFlag("photo_editing") ? (
          <EditButton onClick={showModal} />
        ) : null}
        {!!onRemove && <RemoveButton onClick={handleRemove} />}
      </div>

      {!overlay && modalVisible ? (
        <EditImageModal
          hideModal={hideModal}
          src={src}
          alt={alt}
          onEdit={onEdit}
          updatingImage={updatingImage}
          format={format}
        />
      ) : null}
    </>
  );
};

export default React.forwardRef<Props, HTMLDivElement>((props, ref) => (
  <Image {...props} forwardedRef={ref} />
));

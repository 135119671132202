/* @flow */
import React, { Component } from "react";
import classNames from "classnames";

import tooltipper from "behavioral/tooltipper";

import CheckboxInput from "forms/CheckboxInput";
import H5 from "typography/H5";
import Caption from "typography/Caption";
import AccountBadge from "icons/AccountBadge";
import BoardSelect from "./BoardSelect";
import InstagramPersonalCaption from "./InstagramPersonalCaption";
import InstagramBusinessSettings from "./InstagramBusinessSettings";
import styles from "./index.css";

import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";

type Props = {
  account: AccountData,
  selected: boolean,
  selectedPinterestBoardId?: ?string,
  fullWidth: ?boolean,
  disabled: ?boolean,
  disabledMessaging?: { [string]: string },
  hasError: boolean,
  sendMobileReminder?: Boolean,
  instaReels?: Boolean,
  hasMobileDevices?: Boolean,
  formattedName: string,
  onSelect: (id: string) => void,
  onSelectPinterestBoard?: (
    accountId: string,
    pinterestBoardId: string
  ) => void,
  onChangeInstagramBusinessSetting?: (
    sendMobileReminder: boolean,
    instaReels?: boolean,
    instaStory?: boolean
  ) => void
};

type TooltipProps = {
  message: string
};

const Tooltip = (props: TooltipProps) => {
  return <Caption>{props.message}</Caption>;
};

export default class Option extends Component<Props> {
  static defaultProps: { selected: boolean } = {
    selected: false
  };

  handleSelect = (_evt: Event | boolean) => {
    const {
      onSelect,
      disabled,
      account: { id }
    } = this.props;
    if (!disabled) {
      onSelect(id);
    }
  };

  handleProviderOptionsClick = (evt: Event) => {
    evt.stopPropagation();
  };

  formatCaption = () => {
    const {
      account: { nickname, provider, providerDisplayName }
    } = this.props;
    return provider !== "TWITTER" ? providerDisplayName : `@${nickname ?? ""}`;
  };

  shouldComponentUpdate(nextProps: Props) {
    // This, too, is hacky. I noticed that the account list was jumping when clicking
    // neighboring accounts to those that were disabled and tooltipped. It seemed like
    // a re-rendering thing, so I tried this out, since we don't need to update the
    // checkbox selection state (i.e., it's selected state should not be able to be
    // updated). It seems to work so sticking with it for now.
    const { account, disabled, disabledMessaging = {} } = nextProps;
    if (disabled && Boolean(disabledMessaging[account.id])) {
      return false;
    }
    return true;
  }

  // The `isFullWidthOverride` option is kinda hacky, but it allows to wrap the main
  // `<Option />` component with a tooltip and expand to the tooltip's width.
  renderOption = ({
    isFullWidthOverride = true
  }: {
    isFullWidthOverride?: boolean
  }) => {
    const {
      selected,
      fullWidth,
      account,
      selectedPinterestBoardId,
      disabled,
      hasError,
      formattedName,
      sendMobileReminder,
      instaReels,
      instaStory,
      hasMobileDevices,
      onSelectPinterestBoard,
      onChangeInstagramBusinessSetting
    } = this.props;

    const showInstagramPersonalCaption =
      account.provider.toUpperCase() === "INSTAGRAM_PERSONAL" &&
      selected &&
      !!onSelectPinterestBoard;

    const showInstagramBusinessSettings =
      (account.provider.toUpperCase() === "INSTAGRAM_BUSINESS" || account.provider.toUpperCase() === "INSTAGRAM_PROFESSIONAL") &&
      selected &&
      !!onSelectPinterestBoard;
 
    const showPinterestBoardSelector =
      account.provider.toUpperCase() === "PINTEREST" &&
      selected &&
      !!onSelectPinterestBoard;

    return (
      <div
        className={classNames(styles.root, {
          [styles.fullWidth]: fullWidth || isFullWidthOverride
        })}
        title={account.description}
        disabled={disabled}
        onClick={this.handleSelect}
      >
        <CheckboxInput
          value={selected}
          className={styles.input}
          extraMargin
          hasError={hasError}
          onChange={this.handleSelect}
          disabled={!!disabled}
        />
        <div className={styles.account}>
          <div className={styles.accountInfo}>
            <div className={styles.details}>
              <H5 className={styles.name}>{formattedName}</H5>
              <Caption helpText className={styles.caption}>
                {this.formatCaption()}
              </Caption>
            </div>
            <AccountBadge value={account} tooltipDisabled />
          </div>
          <div
            className={styles.providerOptions}
            onClick={this.handleProviderOptionsClick}
          >
            {showInstagramPersonalCaption && <InstagramPersonalCaption />}
            {showInstagramBusinessSettings && (
              <InstagramBusinessSettings
                sendMobileReminder={sendMobileReminder}
                instaReels={instaReels}
                instaStory={instaStory}
                hasMobileDevices={hasMobileDevices}
                onChange={onChangeInstagramBusinessSetting}
              />
            )}
            {showPinterestBoardSelector && (
              <BoardSelect
                boards={account.pinterestBoards}
                selectedBoardId={selectedPinterestBoardId}
                onSelect={
                  onSelectPinterestBoard &&
                  onSelectPinterestBoard.bind(null, account.id)
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { account, disabled, fullWidth, disabledMessaging = {} } = this.props;

    if (disabled && Boolean(disabledMessaging[account.id])) {
      const targetClassName = fullWidth ? styles.fullWidth : styles.tooltipped;
      const Tooltipped = tooltipper(Tooltip, { targetClassName })(
        this.renderOption
      );
      return <Tooltipped message={disabledMessaging[account.id]} />;
    }

    return this.renderOption({ isFullWidthOverride: false });
  }
}

/* @flow */
import React from "react";
import type { Account } from "types";

import styles from "./index.css";

type Props = {
  account: Account
};

export const HeaderText = ({ account }: Props) => {
  const timeText = "22h"
  return <div className={styles.root}>{account.nickname + " " + timeText}</div>
};

export default HeaderText;

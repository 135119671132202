import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import H4 from "typography/H4";
import Label from "typography/Label";
import Caption from "typography/Caption";
import Link from "links/Link";
import Option from "./Option";

import type { FlashType } from "reducers/flash";
import type {
  updateUtmParameters as UpdateUtmParametersResult,
  updateUtmParametersVariables
} from "graphql-types/updateUtmParameters";

import styles from "./index.css";

type Props = {
  flash: (type: FlashType, body: string) => void,
  isEnabled: boolean,
  updateUtmParameters?: ({
    variables: updateUtmParametersVariables
  }) => Promise<{ data: UpdateUtmParametersResult }>,
  updateUtmParametersLoading?: boolean
};

const HELP_LINK =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/the-add-new-content-button/" +
  "add-utm-parameters-to-your-edgar-links";

const UtmParametersSettings = ({
  flash,
  isEnabled,
  updateUtmParameters,
  updateUtmParametersLoading
}: Props) => {
  const [state, updateState] = useState({
    isEnabled: isEnabled,
    isModalOpen: false
  });
  const closeModal = _evt => {
    updateState({ ...state, isModalOpen: false });
  };
  const selectOption = (isEnabled: boolean) => {
    updateState({ ...state, isEnabled });
  };
  return (
    <>
      {state.isModalOpen && (
        <Modal
          footer={
            <>
              <Button
                className={styles.buttonCancel}
                onClick={closeModal}
                type="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  state.isEnabled === isEnabled || updateUtmParametersLoading
                }
                onClick={(): void => {
                  const promise = updateUtmParameters?.({
                    variables: { isEnabled: state.isEnabled }
                  });
                  if (promise) {
                    promise.then(response => {
                      const errors =
                        response?.data?.updateUtmParameters?.errors || [];
                      if (errors.length > 0) {
                        flash("error", errors[0]);
                      } else {
                        closeModal();
                        flash(
                          "notice",
                          "UTM parameters have been successfully updated!"
                        );
                      }
                    });
                  }
                }}
                type="primary"
              >
                Apply
              </Button>
            </>
          }
          onClose={closeModal}
          title="UTM Parameter Options"
        >
          <div className={styles.main}>
            <p>
              Choosing 'yes' below means Edgar will append his default UTM
              parameters to any links in your content when you use ed.gr or
              Rebrandly<sup>*</sup> as your link shortener, or if your links are
              unshortened, making it easier for you to track content performance
              in Google Analytics. Learn more about how UTM parameters work{" "}
              <Link href={HELP_LINK} target="_blank">
                here
              </Link>
              .
            </p>
            <div>
              <H4>Edgar's Default UTM Parameters</H4>
              <table>
                <tbody>
                  <tr>
                    <td>Campaign name:</td>
                    <td>meetedgar</td>
                  </tr>
                  <tr>
                    <td>Campaign source:</td>
                    <td>meetedgar.com</td>
                  </tr>
                  <tr>
                    <td>Campaign medium:</td>
                    <td>social</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.options}>
              <Label>
                Do you want to add UTM parameters to all Ed.gr, Rebrandly, or
                unshortened links automatically?
              </Label>
              <Option
                caption="All Ed.gr, Rebrandly, or unshortened links will have UTM parameters automatically added prior to shortening"
                isEnabled={state.isEnabled}
                label="Yes"
                option={true}
                selectOption={selectOption}
              />
              <Option
                caption="UTM parameters will not be added to any links"
                isEnabled={state.isEnabled}
                label="No"
                option={false}
                selectOption={selectOption}
              />
            </div>
            <Caption>
              <sup>*</sup>UTM parameters are only available when using ed.gr or
              Rebrandly for your link shortener. Links shortened with Bitly will
              not have UTM parameters appended to them.
            </Caption>
          </div>
        </Modal>
      )}
      <span className={styles.utmSpan}>
        {isEnabled ? "Yes" : "No"} (
        <Link
          onClick={(): void => {
            updateState({ ...state, isModalOpen: true });
          }}
        >
          Change
        </Link>
        )
      </span>
    </>
  );
};

export default UtmParametersSettings;

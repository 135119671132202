/* @flow */
import React from "react";

import styles from "./index.css";


export const LoadingLine = () => (
  <div className={styles.root}></div>
);

export default LoadingLine;

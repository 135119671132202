import styled from "styled-components";

export const Value = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  margin: 0 0 0 10px;
  padding: 5px 8px;

  &:focus,
  &:active {
    border-color: #e9e9e9;
    outline: none;
  }

  &.readOnly {
    cursor: not-allowed;
  }
`;


export const DropdownValue = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  margin: 0 0 0 10px;
  padding: 5px 8px;
  width: 85px;

  &:focus,
  &:active {
    border-color: #e9e9e9;
    outline: none;
  }

  &.readOnly {
    cursor: not-allowed;
  }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: ${(props) => (props.isDropdownOpen ? "rotate(-135deg)" : "rotate(45deg)")};
    transition: transform 0.3s ease;
    margin-left: 10px;
  }
`;
/* @flow */
import React from "react";
import get from "lodash/get";
import moment from "moment-timezone";
import Skeleton from "react-loading-skeleton";

import styles from "./index.css";
import HtmlizedText from "components/common/HtmlizedText";
import AccountAvatarWithBadge from "icons/AccountAvatarWithBadge";
import Card from "layout/Card";
import Link from "links/Link";
import LinkPreview from "links/LinkPreview";
import LinkPreviewHint from "components/common/content/common/LinkPreviewHint";
import SocialMediaIcon from "icons/SocialMediaIcon";
import Icon from "icons/Icon";
import CategoryIcon from "icons/CategoryIcon";
import Thumbnail from "components/Thumbnail";
import AdvancedSettingsDescription from "../../../../components/common/content/common/AdvancedSettingsDescription";

import type { getUpcomingPosts_upcomingPosts_data as UpcomingPostData } from "graphql-types/getUpcomingPosts";

const RANDOM_CATEGORY_ID = "0";

function accountName(account) {
  if (!account) {
    return null;
  }
  const { name, nickname } = account;
  return name || (nickname ? `@${nickname}` : null);
}

type Props = {
  value?: UpcomingPostData,
  timezone?: string,
  onSkipUpcomingPost?: (string, string, string) => void,
  onDeleteSchedule?: (scheduleId: number) => void
};

export const Post = ({
  value,
  onSkipUpcomingPost,
  onDeleteSchedule,
  timezone
}: Props) => {
  const expiresAt = get(value, "content.expiresAt");
  const provider = get(value, "account.provider");
  const platform = get(value, "account.platform");
  const nickname = get(value, "account.nickname");
  const isOk = get(value, "account.isOk");
  const account = get(value, "account");
  const pinterestBoards = get(value, "pinterestBoards");
  const sendAt = get(value, "sendAt");
  const scheduled = get(value, "scheduled");
  const text = get(value, "text");
  const mentionNames = get(value, "mentionNames");
  const rawRichTextEntityMap = get(value, "rawRichTextEntityMap");
  const content = get(value, "content");
  const images = get(value, "images");
  const videos = get(value, "videos");
  const video = videos && videos?.length > 0 ? videos[0] : null;
  const linkPreview = get(value, "linkPreview");
  const link = get(value, "link");
  const category = get(value, "category");
  const selectedViaRandom = get(value, "selectedViaRandom");
  const useOnce = get(value, "useOnce");
  const sendMobileReminder = get(value, "sendMobileReminder");
  const instaReels = get(value, "content.instaReels");
  const instaStory = get(value, "content.instaStory");

  const localSendAt = sendAt && timezone ? moment.tz(sendAt, timezone) : null;
  const localExpiresAt =
    expiresAt && timezone ? moment.tz(expiresAt, timezone) : null;

  const handleSkipPost = () =>
    value &&
    onSkipUpcomingPost &&
    onSkipUpcomingPost(
      value.content.id,
      value.account.id,
      selectedViaRandom ? RANDOM_CATEGORY_ID : value.category.id
    );

  const handleDeleteSchedule = () => {
    if (value && !!value.scheduleId && onDeleteSchedule) {
      return onDeleteSchedule(value.scheduleId);
    }
  };

  const hasPinterestBoards = provider === "PINTEREST" && pinterestBoards;
  const editPath = value && `/composer/${value.content.id}`;

  return (
    <Card>
      <div
        className={styles.header}
        style={{ marginBottom: hasPinterestBoards ? "24px" : "12px" }}
      >
        {isOk === false ? (
          <div className={styles.accountError}>
            <Icon type="ban" /> This account has become disconnected. To resume
            posting, please go to the{" "}
            <Link href="/accounts">Accounts page</Link> and follow the prompts
            to reconnect.
          </div>
        ) : null}
        <AccountAvatarWithBadge value={account} size="medium" />
        <div className={styles.left}>
          <span className={styles.accountName}>
            {accountName(account) || <Skeleton width={125} />}
          </span>
          {(provider === "TWITTER" || provider === "PINTEREST") && nickname ? (
            <span className={styles.accountDisplayName}>{`@${nickname}`}</span>
          ) : null}
          {hasPinterestBoards ? (
            <span className={styles.pinterestBoard}>
              Board: {pinterestBoards.map(b => b.name).join(", ")}
            </span>
          ) : null}
          {provider === "INSTAGRAM_BUSINESS" ? (
            <span className={styles.pinterestBoard}>
              {sendMobileReminder
                ? "Mobile reminder"
                : instaReels
                ? "IG Reels"
                : instaStory ? "IG Story" : "Direct to feed"}
            </span>
          ) : null}
        </div>

        <div className={styles.right}>
          <span className={styles.scheduledAt}>
            {localSendAt ? (
              `Scheduled for ${localSendAt.format("h:mm a")}`
            ) : (
              <Skeleton width={150} />
            )}
          </span>
          <span className={scheduled ? styles.twoActions : styles.oneAction}>
            {scheduled ? (
              <>
                {!!value?.scheduleId && (
                  <Link
                    onClick={handleDeleteSchedule}
                    className={styles.trashButton}
                    confirm={true}
                    confirmType={"warning"}
                    confirmTitle={"Are you sure?"}
                    confirmMessage={
                      "This is going to completely remove this scheduled timeslot from your queue" +
                      " and schedule calendar. You will not be able to undo this action."
                    }
                  >
                    <Icon type="trash" />
                  </Link>
                )}
                <Link
                  onClick={handleSkipPost}
                  className={styles.skipButton}
                  title="Skip single upcoming post"
                >
                  <Icon type="fast-forward" />
                </Link>
              </>
            ) : (
              <Link
                onClick={handleSkipPost}
                className={styles.skipButton}
                confirm={true}
                confirmType={"warning"}
                confirmTitle={
                  "This post was scheduled for a specific date and time using Schedule Settings when the post was created."
                }
                confirmMessage={
                  "Skipping this post will remove the chosen date and time and place the post back in the regular posting rotation for this category. Are you sure you want to remove the specific date and time?"
                }
              >
                <Icon type="times" />
              </Link>
            )}
            <Link href={editPath} className={styles.editButton}>
              <Icon type="pencil" />
            </Link>
          </span>
        </div>
      </div>

      <div className={styles.mainContent}>
        <Thumbnail
          contentId={get(content, "id")}
          images={images}
          video={video}
          videos={videos}
        />
        {text ? (
          <HtmlizedText
            className={styles.text}
            value={text}
            rawRichTextEntityMap={rawRichTextEntityMap}
            mentions={mentionNames}
          />
        ) : null}
      </div>

      {linkPreview && linkPreview.url ? (
        <div className={styles.linkPreview}>
          <LinkPreview pageScrape={linkPreview} platforms={[platform]} />
        </div>
      ) : null}

      {linkPreview && !linkPreview.url && link ? (
        <LinkPreviewHint link={link} platform={platform} />
      ) : null}

      {provider === "PINTEREST" && link ? (
        <div className={styles.destinationLink}>
          <SocialMediaIcon value="pinterest" tiny />
          &nbsp; destination link:{" "}
          <Link href={link} target="_blank">
            {link}
          </Link>
        </div>
      ) : null}

      <AdvancedSettingsDescription useOnce={useOnce} />

      <div className={styles.footer}>
        <CategoryIcon category={category} />
        &nbsp;
        <span>{get(category, "name") || <Skeleton width={200} />}</span>
        {selectedViaRandom ? (
          <span className={styles.selectedViaRandom}>Selected via Random</span>
        ) : null}
        {localExpiresAt ? (
          <span className={styles.expires}>
            Expires on {localExpiresAt.format("MMM D, YYYY")} at{" "}
            {localExpiresAt.format("h:mm a")}
          </span>
        ) : null}
      </div>
    </Card>
  );
};

export default Post;

/* @flow */
import React, { Component } from "react";

import { featureFlag } from "util";
import tooltipper from "behavioral/tooltipper";
import CheckboxInput from "forms/CheckboxInput";
import Caption from "typography/Caption";

import BoardSelect from "./BoardSelect";
// import LiAdAccountSelect from "./LiAdAccountSelect";
import InstagramPersonalCaption from "./InstagramPersonalCaption";
import InstagramBusinessSettings from "./InstagramBusinessSettings";
import LinkedInPostSelect from "./LinkedInPostSelect";
import AccountBadge from "./AccountBadge";

import styles from "./index.css";
import type { Props, TooltipProps } from "./types.js";
import {
  Wrapper,
  CheckboxWrapper,
  Data,
  Info,
  Label,
  ProviderOptions
} from "./styles";

const Tooltip = (props: TooltipProps) => {
  return <Caption>{props.message}</Caption>;
};

export default class Option extends Component<Props> {
  static defaultProps: { selected: boolean } = {
    selected: false
  };

  handleSelect = (_evt: Event | boolean) => {
    const {
      onSelect,
      disabled,
      account: { id }
    } = this.props;
    if (!disabled) {
      onSelect(id);
    }
  };

  handleProviderOptionsClick = (evt: Event) => {
    evt.stopPropagation();
  };

  formatCaption = () => {
    const {
      account: { nickname, provider, providerDisplayName }
    } = this.props;
    return provider !== "TWITTER" ? providerDisplayName : `@${nickname ?? ""}`;
  };

  shouldComponentUpdate(nextProps: Props) {
    // This, too, is hacky. I noticed that the account list was jumping when clicking
    // neighboring accounts to those that were disabled and tooltipped. It seemed like
    // a re-rendering thing, so I tried this out, since we don't need to update the
    // checkbox selection state (i.e., it's selected state should not be able to be
    // updated). It seems to work so sticking with it for now.
    const { account, disabled, disabledMessaging = {} } = nextProps;
    if (disabled && Boolean(disabledMessaging[account.id])) {
      return false;
    }
    return true;
  }

  renderOption = () => {
    const {
      selected,
      account,
      accountRelationship,
      selectedPinterestBoardId,
      selectedLiadAccountId,
      disabled,
      hasError,
      formattedName,
      sendMobileReminder,
      instaReels,
      instaStory,
      linkedinCarousel,
      hasMobileDevices,
      onSelectPinterestBoard,
      onSelectLiAdAccount,
      onChangeInstagramBusinessSetting,
      onChangeLinkedinPostType
    } = this.props;

    const showInstagramPersonalCaption =
      account.provider.toUpperCase() === "INSTAGRAM_PERSONAL" &&
      selected &&
      !!onSelectPinterestBoard &&
      !!onSelectLiAdAccount;

    const showInstagramBusinessSettings =
      (account.provider.toUpperCase() === "INSTAGRAM_BUSINESS" || account.provider.toUpperCase() === "INSTAGRAM_PROFESSIONAL") &&
      selected &&
      !!onSelectPinterestBoard &&
      !!onSelectLiAdAccount;

    const showPinterestBoardSelector =
      account.provider.toUpperCase() === "PINTEREST" &&
      selected &&
      !!onSelectPinterestBoard &&
      !!onSelectLiAdAccount;

    const showLiAdAccountSelector =
      account.provider.toUpperCase() === "LINKEDIN_COMPANY" &&
      selected &&
      !!onSelectPinterestBoard &&
      !!onSelectLiAdAccount;

    return account.provider === "GOOGLE_BUSINESS" &&
      !featureFlag("google_business") ? null : (
      <Wrapper
        title={account.description}
        disabled={disabled}
        onClick={this.handleSelect}
        className={selected ? "active" : ""}
      >
        <CheckboxWrapper>
          <CheckboxInput
            value={selected}
            extraMargin
            hasError={hasError}
            onChange={this.handleSelect}
            disabled={!!disabled}
            newStyle
          />
        </CheckboxWrapper>
        <Data>
          <Info>
            <AccountBadge value={account} tooltipDisabled />
            <Label>
              <b>{formattedName}</b> - <span>{this.formatCaption()}</span>
            </Label>
          </Info>

          <ProviderOptions
            onClick={this.handleProviderOptionsClick}
            className={
              showInstagramPersonalCaption ||
                showLiAdAccountSelector ||
                showInstagramBusinessSettings ||
                showPinterestBoardSelector
                ? "mt"
                : ""
            }
          >
            {showInstagramPersonalCaption && <InstagramPersonalCaption />}
            {showInstagramBusinessSettings && (
              <InstagramBusinessSettings
                sendMobileReminder={sendMobileReminder}
                instaReels={instaReels}
                instaStory={instaStory}
                hasMobileDevices={hasMobileDevices}
                onChange={onChangeInstagramBusinessSetting}
              />
            )}
            {showPinterestBoardSelector && (
              <BoardSelect
                boards={account.pinterestBoards}
                selectedBoardId={selectedPinterestBoardId}
                onSelect={
                  onSelectPinterestBoard &&
                  onSelectPinterestBoard.bind(null, account.id)
                }
              />
            )}

            {showLiAdAccountSelector && (
              // <BoardSelect
              //   boards={account.linkedinAdAccounts}
              //   selectedBoardId={selectedPinterestBoardId}
              //   onSelect={
              //     onSelectPinterestBoard &&
              //     onSelectPinterestBoard.bind(null, account.id)
              //   }
              // />
              <LinkedInPostSelect
                account={account}
                accountRelationship={accountRelationship}
                sendMobileReminder={sendMobileReminder}
                linkedinCarousel={linkedinCarousel}
                hasMobileDevices={hasMobileDevices}
                onChange={onChangeLinkedinPostType}
                onSelectLiAdAccount={onSelectLiAdAccount}
                selectedLiadAccountId={selectedLiadAccountId}
              />
            )}
          </ProviderOptions>
        </Data>
      </Wrapper>
    );
  };

  render() {
    const { account, disabled, disabledMessaging = {} } = this.props;

    if (disabled && Boolean(disabledMessaging[account.id])) {
      const targetClassName = styles.tooltipped;
      const Tooltipped = tooltipper(Tooltip, { targetClassName })(
        this.renderOption
      );
      return <Tooltipped message={disabledMessaging[account.id]} />;
    }

    return this.renderOption();
  }
}

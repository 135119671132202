/* @flow */
import { gql } from "@apollo/client";

export default gql`
  query getSchedulePosts($filter: PostFilter) {
    schedulePosts(filter: $filter) {
      id
      sendAt
      sentAt
      url
      videoStatus
      sendOn
      scheduleId
      recurring
      schedule{
        id
        category {
          id
          name
          colorIndex
        }
          content {
            id
            sendMobileReminder
            category {
              id
              name
              colorIndex
            }
            variations {
              id
            }
          }
      }
      category {
        id
        name
        colorIndex
      }
      account {
        id
        name
        provider
        image
      }
      content {
        id
        sendMobileReminder
        category {
          id
          name
          colorIndex
        }
        variations {
          id
        }
      }
      variation {
        id
        displayText
        text
        timesSent
        images {
          id
        }
        videos {
          id
        }
        shortLinks {
          totalClicks
        }
      }
    }
  }
  
`;

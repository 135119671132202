/* @flow */
import React from "react";
import Icon from "icons/Icon";

import styles from "./index.css";


const Footer = () => (
  <div className={styles.root}>
    <div className={styles.cameraDiv}>
      <Icon type="camera"/>
    </div>
    <div className={styles.sendMessageDiv}>
      <div>Send Message</div>
      <div>...</div>
    </div>
    <Icon type="paper-plane"/>
  </div>
);

export default Footer;

/* @flow */
import React from "react";
import Select from "../common/Select";

const options = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const UseOnce = ({ toggle }) => (
  <Select toggle={toggle} label="Use once" filterKey="useOnce" options={options} />
);

export default UseOnce;

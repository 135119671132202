/* @flow */
import React from "react";
import Header from "./Header";
import Media from "./Media";
import Footer from "./Footer";
import LoadingLine from "./LoadingLine";
import styles from "./index.css";

import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";

type Props = {
  variation: VariationData,
  account: AccountData
};

const InstagramStory = ({ account, variation }: Props) => (
  <div className={styles.root}>
    <LoadingLine />
    <Header account={account} />
    <Media variation={variation} />
    <Footer />
  </div>
);

export default InstagramStory;

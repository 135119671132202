/* @flow */
import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import some from "lodash/some";
import { Alert } from "layout";
import { Label } from "typography";
import CategorySelect from "components/common/CategorySelect";
import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import NewAccountSelect from "components/common/NewAccountSelect";
import styles from "./index.css";
import { featureFlag } from "util";

import type { getSchedule_accounts as AccountData } from "graphql-types/getSchedule";
import type { CalendarItem } from "../Calendar/util.js";

import moment from "moment";

import Tabs from "./Tabs";
import PostSelect from "./PostSelect";
import SendAt from "./SendAt";
import SendOn from "./SendOn";
import Repeat from "./Repeat";
import RepeatDay from "./RepeatDay";
import {
  Wrapper,
  Body,
  LeftWrapper,
  Header,
  RightWrapper,
  Footer,
  HeaderTabs,
  MobileTabs
} from "./styles";
import ConfirmationModal from "../Calendar/ConfirmationModal/index.js";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

type SubmitProps = {
  sendAt: string,
  accountIds: Set<string>,
  categoryId?: string | null,
  contentId?: string | null,
  sendOn?: Set<string>,
  startAt?: string,
  recurring: boolean,
  id?: ?string
};

type ScheduleModalProps = {
  onClose: (evt: Event) => void,
  onSubmit: (timeslot: SubmitProps) => void,
  onDelete: (timeslotId: string) => void,
  onDismissScheduleSuggestion: (offset: number, platforms: string[]) => void,
  accounts: AccountData[],
  creating: boolean,
  updating: boolean,
  deleting: boolean,
  error?: string | { message?: string },
  calendarItem: CalendarItem | any,
  startAt: string
};

const convertTime12to24 = time12h => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") hours = "00";
  if (modifier === "pm") hours = parseInt(hours, 10) + 12;

  return `${parseInt(hours, 10) <= 9 ? "0" + hours : hours}:${minutes}`;
};

const ScheduleModalFooter = ({
  onCancel,
  onSubmit,
  onDelete,
  creating,
  updating,
  deleting,
  disabled,
  isSuggestedSchedule
}) => (
  <Footer>
    {onSubmit ? (
      <Button
        onClick={onSubmit}
        type="primary"
        loading={creating || updating}
        disabled={deleting || disabled}
      >
        Save
      </Button>
    ) : null}
    <div className={styles.secondaryActions}>
      {onCancel ? (
        <Button
          onClick={onCancel}
          type="secondary"
          disabled={creating || updating || deleting}
        >
          Cancel
        </Button>
      ) : null}
      {onDelete && !disabled ? (
        <Button
          {...(isSuggestedSchedule && {
            confirm: true,
            confirmTitle: "Are you sure you want to delete this time slot?",
            confirmMessage: "This action cannot be undone.",
            confirmLabel: "Yes",
          })}
          onClick={onDelete}
          type="secondary-negative"
          disabled={creating || updating}
          loading={deleting}
        >
          Delete
        </Button>
      ) : null}
    </div>
  </Footer>
);

const ScheduleModal = ({
  onClose,
  onSubmit,
  onDelete,
  onDismissScheduleSuggestion,
  accounts,
  calendarItem,
  creating,
  updating,
  deleting,
  error,
  startAt: startAtProps
}: ScheduleModalProps) => {

  const editing =
    get(calendarItem, "id", false) &&
    get(calendarItem, "__typename") === "Timeslot" || get(calendarItem, "__typename") === 'Post';
  const isSuggestedSchedule =
    get(calendarItem, "__typename") === "ScheduleSuggestion";
  const [selectedAccounts, setSelectedAccounts] = useState(() => {
    const accountsArray = get(calendarItem, "accounts", []);
    if (Array.isArray(accountsArray) && accountsArray.length > 0) {
      return accountsArray.map(a => a.id);
    }

    const accountObject = get(calendarItem, "account", null);
    return accountObject ? [accountObject.id] : [];
  });

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    get(calendarItem, "category.id", null)
  );
  const [sendAt, setSendAt] = useState(get(calendarItem, "sendAt", null));
  const [selectedDay, setSelectedDay] = useState(
    get(calendarItem, "sendOn", null) || moment().format("dddd")
  );
  const [selectedDays, setSelectedDays] = useState(
    selectedDay ? new Set([selectedDay]) : new Set()
  );
  const [repeatType, setRepeatType] = useState("weekly");
  // state to store data for custom repeat option
  const [customOptionData, setCustomOptionData] = useState(null)

  const [customOptionStatement, setCustomOptionStatement] = useState(null);

  const [startAt, setStartAt] = useState(
    get(
      calendarItem,
      "startAt",
      startAtProps && calendarItem.sendOn
        ? moment(startAtProps)
          .startOf("week")
          .add(daysOfWeek.indexOf(calendarItem.sendOn), "days")
          .format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD")
    )
  );
  const [recurring, setRecurring] = useState(
    get(calendarItem, "recurring", true)
  );

  useEffect(() => {
    if (customOptionData) {
      setCustomOptionStatement(`Repeats every ${customOptionData.repeatNumber} ${customOptionData.repeatType === 'weekly' ? customOptionData.repeatNumber > 1 ? 'weeks' : 'week' : customOptionData.repeatNumber > 1 ? 'days' : 'day'}`);
    } else {
      setCustomOptionStatement(null);
    }
  }, [customOptionData])

  const startAtHour = convertTime12to24(calendarItem.sendAt);
  const [errors, setErrors] = useState({
    categoryId: false,
    sendOn: false,
    accountIds: false,
    postId: false,
    previousDate: get(calendarItem, "__typename") === 'Post' ? true : false,
    // editing &&
    // startAtProps &&
    // moment(`${startAtProps} ${startAtHour}`)
    //   .startOf("week")
    //   .set({
    //     hour: parseInt(startAtHour.split(":")[0]),
    //     minute: parseInt(startAtHour.split(":")[1])
    //   })
    //   .add(daysOfWeek.indexOf(calendarItem.sendOn), "days")
    //   .isBefore(moment())
    nonrecurringPastDate: !!(
      editing &&
      !recurring &&
      startAtProps &&
      moment(`${startAtProps} ${startAtHour}`)
        .startOf("week")
        .set({
          hour: parseInt(startAtHour.split(":")[0]),
          minute: parseInt(startAtHour.split(":")[1])
        })
        .add(daysOfWeek.indexOf(calendarItem.sendOn), "days")
        .isBefore(moment())
    )
  });


  const [singlePostType, setSinglePostType] = useState(() => {
    const calendarType = get(calendarItem, "__typename");
    const hasScheduleAndContent = get(calendarItem, "schedule") && get(calendarItem, "schedule.content");

    if (calendarType === 'Post' && !hasScheduleAndContent) {
      return false;
    }
    return !!get(calendarItem, "content");
  });
  const [selectedPost, setSelectedPost] = useState(
    get(calendarItem, "content", undefined)
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('this');

  const [openUpdateConfirmationModal, setOpenUpdateConfirmationModal] = useState(false);
  const [selectedUpdateOption, setSelectedUpdateOption] = useState('this');
  const [dateChanged, setDateChanged] = useState(false);

  const correspondingSchedules = get(calendarItem, "correspondingScheduleIds");
  const remainingSchedule = get(calendarItem, "remaining");




  function onChangeDay(day, checked) {
    if (checked) {
      selectedDays.add(day);
      setSelectedDays(new Set(selectedDays));
    } else {
      selectedDays.delete(day);
      setSelectedDays(new Set(selectedDays));
    }
    setErrors({ ...errors, sendOn: false });
  }

  function handleChangeCategory(category) {
    setSelectedCategoryId(category.id);
    setErrors({ ...errors, categoryId: false });
  }

  function handleChangeAccounts(accountIds) {
    setSelectedAccounts(accountIds);
    setErrors({ ...errors, accountIds: false });
  }

  function handleStartAt(date: string) {
    if (editing) {
      let changedDate = new Date(date);
      let previousDate = new Date(get(
        calendarItem,
        "startAt",
        startAtProps && calendarItem.sendOn
          ? moment(startAtProps)
            .startOf("week")
            .add(daysOfWeek.indexOf(calendarItem.sendOn), "days")
            .format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      ))
      if (changedDate.getTime() === previousDate.getTime()) {
        setDateChanged(false);
      } else {
        setDateChanged(true);
      }
    }
    // Change sendAt to current hour:minute if sendOn is today and sendAt is past
    // if (date !== startAt && date === moment().format("YYYY-MM-DD")) {
    //   const sendAtMoment = moment(`${date} ${convertTime12to24(sendAt)}`);
    //   if (sendAtMoment.diff(moment(), "minutes") < 0) {
    //     setSendAt(moment().format("hh:mm a"));
    //   }
    // }

    setStartAt(date);
  }

  function handleChangePost(post: any) {
    setSelectedPost(post);
    setErrors({ ...errors, postId: false });
  }

  function onOkClick() {
    const categoryId = selectedCategoryId;
    const accountIds = selectedAccounts;
    const sendOn = editing
      ? selectedDay
      : recurring
        ? selectedDays
        : new Set([moment(startAt).format("dddd")]);
    const id = editing ? get(calendarItem, "id") : null;

    const newErrors = {
      ...errors,
      sendOn: !sendOn || sendOn.size < 1,
      categoryId: !singlePostType && !categoryId,
      accountIds: accountIds.length < 1,
      postId: singlePostType && !selectedPost
    };

    setErrors(newErrors);

    if (
      some(newErrors) ||
      (!singlePostType && !categoryId) ||
      (singlePostType && !selectedPost)
    ) {
      return;
    }

    const attrs = {
      sendAt,
      accountIds,
      categoryId: singlePostType ? null : categoryId,
      startAt,
      sendOn,
      recurring,
      contentId: singlePostType ? selectedPost?.id : null,
      id,
      recurrenceType: repeatType,
      ...(customOptionData && {
        repeatInterval: customOptionData.repeatNumber, ...(customOptionData.endsOn === 'on' && {
          customEndDate: customOptionData.date
        })
      })
    };

    if (!id) {
      delete attrs.id;
    }
    if (selectedUpdateOption && editing) {
      onSubmit(attrs, selectedUpdateOption);
    }
    else {
      onSubmit(attrs);

    }

  }

  function handleSubmit() {
    const correspondingScheduleIds = get(calendarItem, "correspondingScheduleIds");
    if (editing && (recurring || remainingSchedule) && correspondingScheduleIds?.length > 0) {
      setOpenUpdateConfirmationModal(!openUpdateConfirmationModal);
    } else {
      onOkClick();
    }

  }

  function handleDelete() {
    const correspondingScheduleIds = get(calendarItem, "correspondingScheduleIds");
    if ((recurring || remainingSchedule) && correspondingScheduleIds?.length > 0) {
      setOpenConfirmationModal(!openConfirmationModal);
    } else {
      onDeleteOkClick();
    }
  }

  function onDeleteOkClick() {
    const id = get(calendarItem, "id");
    const type = get(calendarItem, "__typename");
    if (type === "Timeslot") {
      onDelete(id, selectedOption);
    } else if (type === "ScheduleSuggestion") {
      onDismissScheduleSuggestion(
        get(calendarItem, "localWeekOffset"),
        get(calendarItem, "platforms")
      );
    }
  }

  function handleTab(value: boolean) {
    if (errors.previousDate || errors.nonrecurringPastDate) return;
    if (value !== singlePostType) {
      setErrors({ ...errors, postId: false, categoryId: false });
    }
    setSinglePostType(value);
  }

  return (
    <div>
      <Modal
        onClose={onClose}
        newFooter={
          <ScheduleModalFooter
            onCancel={onClose}
            onSubmit={handleSubmit}
            onDelete={editing || isSuggestedSchedule ? handleDelete : null}
            creating={creating}
            updating={updating}
            deleting={deleting}
            disabled={errors.previousDate || errors.nonrecurringPastDate}
            isSuggestedSchedule={isSuggestedSchedule}
          />
        }
        size="legacy"
      // closeOnOverlayClick
      >

        <Wrapper>
          <ConfirmationModal title={'Delete recurring event'} showModal={openConfirmationModal} onClose={setOpenConfirmationModal} selectedOption={selectedOption} setSelectedOption={setSelectedOption} onOkClick={onDeleteOkClick} />

          <ConfirmationModal title={'Edit recurring event'} showModal={openUpdateConfirmationModal} onClose={setOpenUpdateConfirmationModal} selectedOption={selectedUpdateOption} setSelectedOption={setSelectedUpdateOption} onOkClick={onOkClick} dateChanged={dateChanged} />


          <Header>
            <h3>{editing ? "Edit time slot" : "Add time slot"}</h3>
            {featureFlag("schedule_v3") && (
              <HeaderTabs>
                <Tabs
                  singlePostType={singlePostType}
                  setSinglePostType={handleTab}
                  disabled={errors.previousDate || errors.nonrecurringPastDate}
                />
              </HeaderTabs>
            )}
          </Header>

          <Body>
            <LeftWrapper>
              <NewAccountSelect
                accounts={accounts}
                selected={selectedAccounts}
                onChange={handleChangeAccounts}
                hasError={errors.accountIds}
                className={styles.accountSelect}
                allowMultipleTwitter
                stackOptions
                readOnly={errors.previousDate || errors.nonrecurringPastDate}
              />
            </LeftWrapper>

            <RightWrapper>
              {featureFlag("schedule_v3") && (
                <MobileTabs>
                  <Tabs
                    singlePostType={singlePostType}
                    setSinglePostType={handleTab}
                    disabled={errors.previousDate || errors.nonrecurringPastDate}
                  />
                </MobileTabs>
              )}

              {featureFlag("schedule_v3") && singlePostType ? (
                <PostSelect
                  selectPost={handleChangePost}
                  selectedPost={selectedPost}
                  errors={errors}
                />
              ) : (
                <div className={styles.category}>
                  <div className={styles.labelContainer}>
                    <Label
                      htmlFor="category"
                      className={styles.sendAtAndCategoryLabel}
                    >
                      Category
                    </Label>
                  </div>
                  <CategorySelect
                    id="category"
                    value={selectedCategoryId}
                    addRandomCategory
                    disableDefaultCategory
                    onChange={handleChangeCategory}
                    hasError={errors.categoryId}
                    readOnly={
                      !!errors.previousDate || !!errors.nonrecurringPastDate
                    }
                  />
                </div>
              )}
              <SendAt
                value={sendAt}
                onChange={setSendAt}
                readOnly={errors.previousDate || errors.nonrecurringPastDate}
              />
              {
                correspondingSchedules?.length === 0 ? <></> :
                  <SendOn
                    startAt={startAt}
                    onChange={handleStartAt}
                    readOnly={errors.previousDate || errors.nonrecurringPastDate}
                  />
              }
              {!editing ? (
                <Repeat
                  recurring={recurring}
                  setRecurring={setRecurring}
                  selectedDays={selectedDays}
                  errors={errors}
                  onChangeDay={onChangeDay}
                  repeatType={repeatType}
                  setRepeatType={setRepeatType}
                  customOptionData={customOptionData}
                  setCustomOptionData={setCustomOptionData}
                  customOptionStatement={customOptionStatement}
                />
              ) : correspondingSchedules?.length === 0 ? (
                <RepeatDay
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                  recurring={recurring}
                  setRecurring={setRecurring}
                  readOnly={errors.previousDate || errors.nonrecurringPastDate}
                />
              ) : <></>}
              {error && typeof error === "string" ? (
                <Alert type="error">{error}</Alert>
              ) : error && error?.message ? (
                <Alert type="error">{error?.message}</Alert>
              ) : errors.previousDate ? (
                <Alert type="alert">
                  You can't update timeslots from previous dates.
                </Alert>
              ) : errors.nonrecurringPastDate ? (
                <Alert type="alert">
                  You can't update does not repeat timeslots from past dates.
                </Alert>
              ) : null}
            </RightWrapper>
          </Body>
        </Wrapper>
      </Modal>

    </div>


  );
};

export default ScheduleModal;

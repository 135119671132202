import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const LabelIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 7px;
`;

export const DropDownWrapper = styled.div`
  margin: 20px 0 0 -80px;
`;

export const DropDownContent = styled.div`
  max-height: 130px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${({ type }) =>
    type === "repeatDay" &&
    `
    max-height: 70px;
  `}

  ${({ type }) =>
    type === "repeat" &&
    `
    max-height: 100px;
  `}
`;

export const Item = styled.div`
  cursor: pointer;
  padding 6px 20px 6px 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Days = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Day = styled.div`
  align-items: center;
  background: #f0f0f0;
  border-radius: 10px;
  color: #858585;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  justify-content: center;
  margin: 7px;
  width: 24px;

  &.active {
    background: rgba(109, 189, 198, 0.25);
    color: #6dbdc6;
  }
`;

export const DaysError = styled.div``;

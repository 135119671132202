/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation updateScheduleV4($input: UpdateScheduleInputTypeV2!, $type: String!){
    updateScheduleV4(input: $input, type: $type) {
      schedule {
        id
        timeslots{
          id
        }
      }
      errors
    }
  }
`;

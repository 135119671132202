/* @flow */
import React, { Component } from "react";
import ScaledImage from "components/common/ScaledImage";
import { Transition } from "react-transition-group";
import Scroller from "./Scroller";
import ImageXOfYIndicator from "./ImageXOfYIndicator";

import styles from "./index.css";

import type { Image } from "types";

type Props = {
  images: Image[]
};

type MotionType = "moving" | "steady";

type State = {
  activeImage: number,
  motion: MotionType
};

export class ImageCarousel extends Component<Props, State> {
  state: State = { activeImage: 0, motion: "steady" };

  handleClickNext = () => {
    const { activeImage } = this.state;
    this.setState({
      activeImage: activeImage + 1,
      motion: "moving"
    });
  };

  handleClickPrevious = () => {
    const { activeImage } = this.state;
    this.setState({
      activeImage: activeImage - 1,
      motion: "moving"
    });
  };

  render() {
    const { images } = this.props;
    const { activeImage, motion } = this.state;

    const defaultStyle = {
      transition: "transform 0.25s ease-in-out",
      transform: "translateX(0)"
    };

    const transitional = {
      moving: `translateX(-${500 * activeImage}px)`,
      steady: "translateX(0)"
    };

    const targetHeight = images.length > 1 ? 500 : 625;

    const extraProps = images.length > 1 ? {} : { forceScaleDimension: "x" };

    return (
      <div className={styles.root}>
        <div className={styles.scroller}>
          <Scroller
            imageCount={images.length}
            activeImage={activeImage}
            onClickPrevious={this.handleClickPrevious}
            onClickNext={this.handleClickNext}
          />
        </div>

        <div className={styles.imageXOfYIndicator}>
          <ImageXOfYIndicator
            activeImage={activeImage}
            imageCount={images.length}
          />
        </div>

        <div className={styles.allImages}>
          {images.map((image, index) => (
            <Transition
              timeout={250}
              style={{
                ...defaultStyle,
                transform: transitional[motion]
              }}
              in={activeImage == index}
              key={image.url}
            >
              <div className={styles.thisIsNeededForTransitionToInject}>
                <ScaledImage
                  value={image.url}
                  targetHeight={targetHeight}
                  targetWidth={500}
                  className={styles.scaledImage}
                  {...extraProps}
                />
              </div>
            </Transition>
          ))}
        </div>
      </div>
    );
  }
}

export default ImageCarousel;

/* @flow */
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import moment from "moment-timezone";
import type {
  getSchedule_schedules as ScheduleData,
  getSchedule_scheduleSuggestions as ScheduleSuggestionData
} from "graphql-types/getSchedule";

import type {
  getScheduleV2_schedulesV2 as ScheduleDataV2,
  getScheduleV2_scheduleSuggestions as ScheduleSuggestionDataV2
} from "graphql-types/getScheduleV2";

export type CalendarHour = number;
export type CalendarMinute = number;
export type CalendarDay = string;
export type CalendarItem =
  | ScheduleData
  | ScheduleDataV2
  | ScheduleSuggestionData
  | ScheduleSuggestionDataV2;

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
  
function formatSchedule(schedule, timezone = "UTC") {
  return {
    ...schedule,
    sendAt: moment(schedule.sendAt).tz(timezone).format("h:mm a")
  };
}
function groupSchedulesByMinute(schedules, timezone = "UTC") {
  return groupBy(schedules, s => +moment(s.sendAt).tz(timezone).format("m"));
}
function groupSchedulesByHour(schedules, timezone = "UTC") {
  return groupBy(schedules, s => +moment(s.sendAt).tz(timezone).format("H"));
}
function groupSchedulesByDay(schedules) {
  return groupBy(schedules, s => s.sendOn);
}

export type DesktopCalendarData = {
  [CalendarHour]: {
    [CalendarMinute]: {
      [CalendarDay]: CalendarItem[]
    }
  }
};
export function buildDesktopCalendarData(
  schedules: CalendarItem[],
  timezone,
  maxDate
): DesktopCalendarData {
  const filteredSchedule = [...schedules].filter(
    x => {
      return x.scheduleId == null || (moment(x.sendAt).isSameOrBefore(maxDate.tz(timezone).endOf("week").endOf('day'))
      && moment(x.sendAt).isSameOrAfter(maxDate.tz(timezone).startOf("week").startOf('day')) )
    }
  ).map(x => {
    const newObject = {...x};
    newObject.sendOn = daysOfWeek[moment(x.sendAt).tz(timezone).weekday()];
    return newObject
  });
  return reduce(
    // Hour
    groupSchedulesByHour(filteredSchedule, timezone),
    (result, schedulesInHour, hour) => ({
      ...result,
      [hour]: reduce(
        //Hour -> Minute
        groupSchedulesByMinute(schedulesInHour, timezone),
        (resultForHour, schedulesInMinute, minute) => ({
          ...resultForHour,
          [minute]: reduce(
            // Hour -> Minute -> Day
            groupSchedulesByDay(schedulesInMinute, timezone),
            (resultForDay, schedulesInDay, day) => ({
              ...resultForDay,
              [day]: schedulesInDay.map((x) => formatSchedule(x, timezone))
            }),
            {}
          )
        }),
        {}
      )
    }),
    {}
  );
}

export type MobileCalendarData = {
  [CalendarDay]: {
    [CalendarHour]: {
      [CalendarMinute]: CalendarItem[]
    }
  }
};
export function buildMobileCalendarData(
  schedules: CalendarItem[]
): MobileCalendarData {
  return reduce(
    // Day
    groupSchedulesByDay(schedules),
    (result, schedulesInDay, day) => ({
      ...result,
      [day]: reduce(
        // Day -> Hour
        groupSchedulesByHour(schedulesInDay),
        (resultForDay, schedulesInHour, hour) => ({
          ...resultForDay,
          [hour]: reduce(
            // Day -> Hour -> Minute
            groupSchedulesByMinute(schedulesInHour),
            (resultForHour, schedulesInMinute, minute) => ({
              ...resultForHour,
              [minute]: schedulesInMinute.map(formatSchedule)
            }),
            {}
          )
        }),
        {}
      )
    }),
    {}
  );
}

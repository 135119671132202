// @flow
import React from "react";
import { without, intersection } from "lodash";
import TwitterExhaustedAlert from "./TwitterExhaustedAlert";
import { CenterAlignedOnboardingToolTip } from "components/common/OnboardingTooltip";

import type { PostedVariationsCount } from "types";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";
import type { loadContent_content_lastPostedTo as LastPostedToMap } from "graphql-types/loadContent";

import BaseAccountSelect from "./BaseAccountSelect";

type Props = {
  accounts: AccountData[],
  accountRelationships: [],
  selectedAccountIds: string[],
  selectedPinterestBoardsByAccount: { [key: string]: string[] },
  selectedLiAdAccountsByAccount: { [key: string]: string[] },
  showOnboardingTooltip: boolean,
  sendMobileReminder: Boolean,
  instaReels: Boolean,
  instaStory: Boolean,
  linkedinCarousel: Boolean,
  hasMobileDevices: Boolean,
  onNextOnboardingStep: () => void,
  lastPostedTo: LastPostedToMap,
  postedVariationsCount: PostedVariationsCount,
  onSelect: (id: string[]) => void,
  onDeselect: (id: string[]) => void,
  onSelectPinterestBoard: (pinterestBoardId: string, accountId: string) => void,
  onChangeInstagramBusinessSetting: (
    sendMobileReminder: boolean,
    instaReels?: boolean,
    instaStory?: boolean
  ) => void
};

const TOOLTIP_BODY =
  "Select the accounts where you would like this post to publish.";

export const AccountSelect = ({
  accounts,
  accountRelationships,
  selectedAccountIds,
  selectedPinterestBoardsByAccount,
  selectedLiAdAccountsByAccount,
  showOnboardingTooltip,
  sendMobileReminder,
  instaReels,
  instaStory,
  linkedinCarousel,
  hasMobileDevices,
  postedVariationsCount: { twitterPostedVariationsCount, variationsCount },
  lastPostedTo,
  onSelect,
  onDeselect,
  onNextOnboardingStep,
  onSelectPinterestBoard,
  onSelectLiAdAccount,
  onChangeInstagramBusinessSetting,
  onChangeLinkedinPostType
}: Props) => {
  let showTwitterExhaustedAlert = false;
  let disabledAccountIds = [];
  let onDeselectAccountIds = onDeselect;

  if (showOnboardingTooltip && selectedAccountIds.length) {
    onNextOnboardingStep();
  }

  if (twitterPostedVariationsCount > 0) {
    const twitterAccounts = accounts.filter(
      acct => acct.provider === "TWITTER"
    );

    const remainingTwitterPostableVariationsCount =
      variationsCount - twitterPostedVariationsCount;

    if (lastPostedTo?.twitter?.account) {
      const lastPostedToAccount = lastPostedTo?.twitter?.account;
      disabledAccountIds = twitterAccounts
        .filter(account => account.id !== lastPostedToAccount?.id)
        .map(a => a.id);
    }

    if (
      remainingTwitterPostableVariationsCount === 0 ||
      lastPostedTo?.twitter === "DELETED_POST"
    ) {
      disabledAccountIds = twitterAccounts.map(a => a.id);
    }

    onDeselectAccountIds = function (accountIds) {
      const cannotBeDeselected = intersection(
        selectedAccountIds,
        disabledAccountIds
      );
      onDeselect(accountIds.filter(id => !cannotBeDeselected.includes(id)));
    };

    showTwitterExhaustedAlert = true;
  }

  return (
    <div>
      {showOnboardingTooltip && (
        <CenterAlignedOnboardingToolTip tooltipBody={TOOLTIP_BODY} />
      )}
      <BaseAccountSelect
        accounts={accounts}
        accountRelationships={accountRelationships}
        selected={without(selectedAccountIds, ...disabledAccountIds)}
        disabledAccountIds={disabledAccountIds}
        alerts={showTwitterExhaustedAlert ? [<TwitterExhaustedAlert />] : []}
        sendMobileReminder={sendMobileReminder}
        instaReels={instaReels}
        instaStory={instaStory}
        linkedinCarousel={linkedinCarousel}
        hasMobileDevices={hasMobileDevices}
        displayOneTwitterAccountPerAlert={!showTwitterExhaustedAlert}
        selectedPinterestBoardsByAccount={selectedPinterestBoardsByAccount}
        selectedLiAdAccountsByAccount={selectedLiAdAccountsByAccount}
        onSelect={onSelect}
        onDeselect={onDeselectAccountIds}
        onSelectPinterestBoard={onSelectPinterestBoard}
        onSelectLiAdAccount={onSelectLiAdAccount}
        onChangeInstagramBusinessSetting={onChangeInstagramBusinessSetting}
        onChangeLinkedinPostType={onChangeLinkedinPostType}
      />
    </div>
  );
};

export default AccountSelect;

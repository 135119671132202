/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation deleteSchedule($id: ID!) {
    deleteSchedule(id: $id) {
      schedule {
        id
      }
      timeslots{
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;

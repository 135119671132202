/* @flow */
import React from "react";
import ProfilePicture from "./ProfilePicture";
import HeaderText from "./HeaderText";
import Menu from "./Menu";
import Icon from "icons/Icon";

import type { Account } from "types";

import styles from "./index.css";

type Props = {
  account: Account
};

export const Header = ({ account }: Props) => (
  <div className={styles.root}>
    <ProfilePicture account={account} />
    <HeaderText account={account} />
    <div className={styles.closeIcon}>X</div>
    <Menu />
  </div>
);

export default Header;

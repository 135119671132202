// @flow
import React, { Component } from "react";
import pluralize from "pluralize";
import CaptionBase from "typography/Caption";
import Icon from "icons/Icon";
import { onlyOneGIFAllowedForPlatform } from "../../../../util.js";
import { COMPOSER_MAX_IMAGE_COUNT } from "constants";
import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  Platform,
  Provider
} from "graphql-types/loadContent";
import type { MediaUpload } from "types";
import styles from "./index.css";

type Props = {
  video: ?VideoData,
  images: ImageData[],
  videos: VideoData[],
  sendMobileReminder: boolean,
  instaReels: boolean,
  instaStory: boolean,
  pendingMedia: MediaUpload[],
  selectedPlatforms: Platform[],
  selectedProviders: Provider[]
};

export class Caption extends Component<Props> {
  maxImageText() {
    const {
      selectedPlatforms,
      selectedProviders,
      sendMobileReminder,
      images
    } = this.props;

    if (onlyOneGIFAllowedForPlatform(selectedPlatforms, images)) {
      return "(1 GIF maximum)";
    }

    const selectedPlatformLimits = selectedPlatforms.map(
      platform =>
        COMPOSER_MAX_IMAGE_COUNT[
          platform === "INSTAGRAM"
            ? !selectedProviders.includes("INSTAGRAM_BUSINESS") ||
              sendMobileReminder
              ? "INSTAGRAM_MOBILE"
              : "INSTAGRAM_DIRECT"
            : platform
        ]
    );

    const max = Math.min(...selectedPlatformLimits);
    if (max === Infinity) {
      return "";
    }
    return `(${max} maximum)`;
  }

  renderText() {
    const {
      images,
      video,
      videos,
      pendingMedia,
      selectedProviders,
      instaReels,
      instaStory
    } = this.props;

    let text = "";

    // logic only for IG Reels
    if (instaReels || instaStory) {
      text = "1 video uploaded (1 maximum)";

      if (pendingMedia.length > 0) {
        text = "uploading";
      }

      return text;
    }

    if (images.length > 0) {
      text = `${images.length} ${pluralize("image", images.length)}`;
    } else if (video) {
      text = "1 video uploaded (1 maximum)";
    } else if (pendingMedia.length > 0) {
      text = "uploading";
    }

    if (images.length > 0 && videos?.length > 0) {
      text += " and ";
    }

    if (videos?.length > 0) {
      text += `${videos.length} ${pluralize("video", videos.length)}`;
    }

    if (
      text.length > 0 ||
      (selectedProviders.length === 1 &&
        selectedProviders[0] === "INSTAGRAM_BUSINESS")
    ) {
      text += ` ${this.maxImageText()}`;
    }

    return text;
  }

  render() {
    const text = this.renderText();
    return (
      <CaptionBase helpText>
        {text && (
          <>
            <Icon type="info-circle" className={styles.icon} />{" "}
            {this.renderText()}
          </>
        )}
      </CaptionBase>
    );
  }
}

export default Caption;

/* @flow */
import { gql } from "@apollo/client";

export default gql`
  mutation deleteScheduleV4($id: ID!, $type: String) {
    deleteScheduleV4(id: $id, type:$type) {
      schedule {
        id
      }
      timeslots{
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;

/* @flow */
import React from "react";
import { colorFromIndex } from "colors";
import Select from "../common/Select";

import type { loadFilters_categories as CategoryData } from "graphql-types/loadFilters";

type Props = {
  loading: boolean,
  categories: CategoryData[]
};

export const Category = ({ toggle, loading, categories }: Props) => {
  const options = categories.map(c => ({
    value: c.id,
    label: c.name,
    color: colorFromIndex(c.colorIndex)
  }));

  return (
    <Select
      toggle={toggle}
      label="Category"
      filterKey="category"
      loading={loading}
      options={options}
    />
  );
};

export default Category;

/* @flow */
import * as React from "react";
// $FlowFixMe
import { Droppable, Draggable } from "react-beautiful-dnd";
import AutoScrollContainer from "./AutoScrollContainer";
import Image from "./Image";
import Video from "./Video";
import PendingMedia from "./PendingMedia";
import LoadingIcon from "icons/LoadingIcon";
import styles from "./index.css";

import type { MediaUpload } from "types";

// $FlowFixMe
import { DragDropContext } from "react-beautiful-dnd";

export type ListItem = {
  src: string,
  id?: string,
  alt?: string,
  order?: string,
  renderOverlay?: () => React.Node,
  format: string
};

type Props = {
  caption?: React.Node,
  images: ListItem[],
  videos: ListItem[],
  pendingMedia?: MediaUpload[],
  loading?: boolean,
  scrollToNewMedia?: boolean,
  onRemoveImage?: (id: string) => void,
  onRemoveVideo?: (id: string) => void,
  onEditImage?: (oldImageId?: string, cropData: any) => void,
  updatingImage: boolean,
  onDragEnd: (result: any, provided: any) => void
};

const MediaList = ({
  images,
  videos,
  pendingMedia = [],
  caption,
  loading = false,
  scrollToNewMedia = true,
  onRemoveImage,
  onRemoveVideo,
  onEditImage,
  updatingImage,
  onDragEnd,
  instaStory
}: Props) => (
  <div>
    {caption && <div className={styles.caption}>{caption}</div>}
    <DragDropContext
      onDragStart={() => {}}
      onDragUpdate={() => {}}
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="media-list" direction="horizontal">
        {provided => (
          <div
            className={styles.listContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {loading && (
              <div className={styles.overlay}>
                <LoadingIcon className={styles.loadingIcon} />
              </div>
            )}
            <AutoScrollContainer disableAutoScroll={!scrollToNewMedia}>
              {videos.map((item: ListItem) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Video
                    key={item.src}
                    src={item.src}
                    instaStory={instaStory}
                    overlay={item.renderOverlay ? item.renderOverlay() : null}
                    onRemove={
                      onRemoveVideo ? onRemoveVideo.bind(null, item.id) : null
                    }
                  />
                </div>
              ))}
              {images.map((item: ListItem, index) => {
                if (item.id && images.length > 1) {
                  return (
                    <Draggable
                      draggableId={item.id}
                      index={item.order}
                      key={item.id}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Image
                            src={item.src}
                            alt={item.alt}
                            key={item.src}
                            instaStory={instaStory}
                            format={item.format}
                            overlay={
                              item.renderOverlay ? item.renderOverlay() : null
                            }
                            onRemove={
                              onRemoveImage
                                ? onRemoveImage.bind(null, item.id)
                                : null
                            }
                            onEdit={cropData =>
                              onEditImage
                                ? onEditImage(item.id, cropData)
                                : null
                            }
                            updatingImage={updatingImage}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                } else {
                  return (
                    <div key={index}>
                      <Image
                        src={item.src}
                        alt={item.alt}
                        key={item.src}
                        instaStory={instaStory}
                        format={item.format}
                        overlay={
                          item.renderOverlay ? item.renderOverlay() : null
                        }
                        onRemove={
                          onRemoveImage
                            ? onRemoveImage.bind(null, item.id)
                            : null
                        }
                        onEdit={(cropData: any) =>
                          onEditImage ? onEditImage(item.id, cropData) : null
                        }
                        updatingImage={updatingImage}
                      />
                    </div>
                  );
                }
              })}
              {pendingMedia.map(({ progress, fileName }) => (
                <PendingMedia key={fileName} progress={progress || 0} />
              ))}
              {provided.placeholder}
            </AutoScrollContainer>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </div>
);
export default MediaList;

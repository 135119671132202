/* @flow */
import React from "react";
import classNames from "classnames";
import { csrfParam, csrfToken } from "../../../../csrf.js";
import Confirmation, { type ConfirmType } from "layout/modals/Confirmation";
import styles from "./index.css";

import type { HttpMethod } from "types";

export type Props = {
  disabled?: boolean,
  url?: string, // TODO: deprecate in favor of href
  href?: string,
  className?: string,
  method?: HttpMethod,
  target?: any,
  tabIndex?: string,
  confirm?: boolean,
  confirmType?: ConfirmType,
  confirmTitle?: string,
  confirmMessage?: string,
  confirmLabel?: string,
  small?: boolean,
  style?: any,
  menuLink?: boolean,
  dataTest?: string,
  children?: any,
  onClick?: EventHandler,
  disableSaveButton?: boolean,
  postsLimitReached?: boolean
};

type State = {
  confirmationOpen: boolean,
  showTooltip: boolean,
  showLimitReachedTooltip: boolean
};

export default class Link extends React.Component<Props, State> {

  static defaultProps: {
    url: string,
    method: HttpMethod,
    disabled: boolean
  };

  state = {
    confirmationOpen: false,
    showTooltip: false,
    showLimitReachedTooltip: false
  }

  handleConfirm = (e: Event) => {
    this.onClick(e);
    this.setState({ confirmationOpen: false });
  };

  handleCancelConfirm = () => {
    this.setState({ confirmationOpen: false });
  };

  handleOpenConfirm = () => {
    this.setState({ confirmationOpen: true });
  };

  onClick: EventHandler = e => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }
    if (this.props.confirm && !this.state.confirmationOpen) {
      e.preventDefault();
      this.handleOpenConfirm();
      return;
    }
    if (this.props.onClick) {
      this.props.onClick(e);
      e.preventDefault();
      return;
    }
    if (this.props.method === "GET") {
      return;
    }
    e.preventDefault();
    this.submit();
  };

  submit(): void {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", this.props.url || this.props.href);
    form.setAttribute("style", "display: none;");

    const methodInput = document.createElement("input");
    methodInput.setAttribute("type", "hidden");
    methodInput.setAttribute("name", "_method");
    methodInput.setAttribute("value", this.props.method);
    form.appendChild(methodInput);

    const token = csrfToken();
    const param = csrfParam();
    if (token && param) {
      const csrfInput = document.createElement("input");
      csrfInput.setAttribute("type", "hidden");
      csrfInput.setAttribute("name", param);
      csrfInput.setAttribute("value", token);
      form.appendChild(csrfInput);
    }

    // Submit button is required in Firefox
    const submitButton = document.createElement("input");
    submitButton.setAttribute("type", "submit");

    // Form must also be added to the document in Firefox
    if (document.body === null) {
      throw new Error("document.body is not available");
    }
    document.body.appendChild(form);

    form.submit();
  }
  handleMouseEnter = () => {
    if (this.props.disableSaveButton) {
      this.setState({ showTooltip: true });
    }
    if (this.props.postsLimitReached) {
      this.setState({ showLimitReachedTooltip: true });
    }
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false, showLimitReachedTooltip: false });
  };

  renderAnchor() {
    const {
      className = "",
      url,
      href,
      disabled,
      method,
      target,
      tabIndex = "",
      children,
      small,
      style,
      menuLink,
      dataTest = "",
      disableSaveButton,
      postsLimitReached
    } = this.props;

    return (
      <div
        style={{ display: "inline-block" }}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        <a
          className={classNames(className, {
            [styles.small]: !!small,
            [styles.menuLink]: !!menuLink
          })}
          href={href || url}
          disabled={disabled}
          onClick={this.onClick}
          rel={method === "DELETE" ? "nofollow" : ""}
          target={target}
          tabIndex={tabIndex}
          style={style}
          data-test={dataTest}

        >
          {children}
        </a>
        {this.state.showTooltip && (
          <div className={classNames("notification-tooltip", {
            [styles.tiktokErrorTooltip]: disableSaveButton
          })}>
            You need to indicate if your content promotes yourself, a third party, or both.
          </div>
        )}
      </div>

    );
  }

  render() {
    const {
      confirmTitle,
      confirmMessage,
      confirmLabel,
      confirmType
    } = this.props;

    if (this.state.confirmationOpen) {
      return (
        <>
          {this.renderAnchor()}
          <Confirmation
            title={confirmTitle}
            subTitle={confirmMessage}
            type={confirmType}
            confirmLabel={confirmLabel}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancelConfirm}
          />
        </>
      );
    } else {
      return this.renderAnchor();
    }
  }
}

Link.defaultProps = {
  method: "GET",
  url: "",
  disabled: false,
  confirm: false,
  confirmType: "warning",
  confirmMessage: ""
};

import React from "react";
import { ModalWrapper, ButtonGroup, ModalContainer, RadioButton, RadioGroup, Title } from "./styles";
import { Button, type ButtonType } from "buttons";


type ConfirmationProps = {
    title: string,
    showModal: boolean,
    onClose: (evt: Event) => void,
    selectedOption: string,
    setSelectedOption: (evt: Event) => void,
    onClick: (evt: Event) => void,
    dateChanged?: boolean

}

const ConfirmationModal = ({ title, showModal, onClose, selectedOption, setSelectedOption, onOkClick, dateChanged }: ConfirmationProps) => {


    const handleChange = (e) => {
        setSelectedOption(e.target.value);
    };

    if (!showModal) return null;

    return (
        <ModalWrapper>
            <ModalContainer>
                <Title>{title}</Title>
                <RadioGroup>
                    <RadioButton htmlFor="thisEvent">
                        <input
                            type="radio"
                            id="thisEvent"
                            name="event"
                            value="this"
                            checked={selectedOption === 'this'}
                            onChange={handleChange}
                        />
                        This event
                    </RadioButton>
                    <RadioButton htmlFor="thisAndFollowing">
                        <input
                            type="radio"
                            id="thisAndFollowing"
                            name="event"
                            value="following"
                            checked={selectedOption === 'following'}
                            onChange={handleChange}
                        />
                        This and following events
                    </RadioButton>
                    {
                        dateChanged ? <></> : <RadioButton htmlFor="allEvents">
                            <input
                                type="radio"
                                id="allEvents"
                                name="event"
                                value="all"
                                checked={selectedOption === 'all'}
                                onChange={handleChange}
                            />
                            All events
                        </RadioButton>
                    }

                </RadioGroup>
                <ButtonGroup>
                    <div style={{ marginRight: '10px' }}>
                        <Button type="secondary" onClick={() => onClose(false)}>
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button type="primary" onClick={onOkClick}>
                            OK
                        </Button>
                    </div>

                </ButtonGroup>
            </ModalContainer>
        </ModalWrapper>
    );
};


export default ConfirmationModal;
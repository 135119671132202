/* @flow */
import React, { useState } from "react";
import { repeat, zipObject } from "lodash";
import moment from "moment";
import { Element } from "react-scroll";

import DropDown from "layout/DropDown";
import { DAYS, SHORT_DAYS } from "constants";

import { Info } from "../RepeatDay/styles";

import styles from "./index.css";
import SVGIcon from "./icon.svg";
import type { IProps } from "./types.js";
import useRepeat from "./use";

import {
  Wrapper,
  LabelIcon,
  DropDownWrapper,
  DropDownContent,
  Item,
  Days,
  Day,
  DaysError
} from "../SendAt/styles";
import { Value } from "../SendOn/styles";
import CustomDateModal from "../CustomDateModal/index.js";

const SHORT_TO_LONG_DAYS = zipObject(SHORT_DAYS, DAYS);

/** Options for repeat types with labels and values */
const REPEAT_OPTIONS = [
  { label: 'Repeats Weekly', value: 'weekly' },
  { label: 'Does not repeat', value: 'not-repeat' },
  { label: 'Daily', value: 'daily' },
  { label: 'Custom', value: 'custom' },
]

const ScheduleRepeat = (props: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { recurring, setRecurring, selectedDays, onChangeDay, errors, repeatType, setRepeatType, customOptionData, setCustomOptionData, customOptionStatement } = props;
  const { dropdownVisible, showDropdown, hideDropdown } = useRepeat(props);
  const [numberOfRepeats, setNumberOfRepeats] = useState(1);

  const getRepeatOption = (repeatValue) => {
    return REPEAT_OPTIONS.find(option => option.value === repeatValue)
  }

  return (
    <>
      <Wrapper>
        <LabelIcon>
          <img src={SVGIcon} alt="repeat icon" />
        </LabelIcon>

        <Value onClick={showDropdown}>{customOptionStatement ? customOptionStatement : getRepeatOption(repeatType)?.label}</Value>

        <DropDownWrapper>
          <DropDown
            open={dropdownVisible}
            onClose={hideDropdown}
            omitDefaultStyle
          >
            <Element>
              <DropDownContent id="sendAtDropdown" type="repeat" className={styles.repeated}>
                <Item
                  onClick={() => {
                    setRecurring(false);
                    setRepeatType('not-repeat');
                    setCustomOptionData(null)
                  }}
                  active={!recurring}
                >
                  Does not repeat
                </Item>
                <Item
                  onClick={() => {
                    const today = moment().format("dddd");
                    setRecurring(true);
                    setRepeatType("weekly");
                    Object.keys(SHORT_TO_LONG_DAYS).forEach(d => {
                      onChangeDay(
                        SHORT_TO_LONG_DAYS[d],
                        SHORT_TO_LONG_DAYS[d] === today
                      );
                    });
                    setCustomOptionData(null)
                  }}
                  active={recurring}
                >
                  Repeats weekly
                </Item>
                <Item
                  onClick={() => {
                    setRepeatType("daily");
                    setRecurring(true);
                    Object.keys(SHORT_TO_LONG_DAYS).forEach(d => {
                      onChangeDay(SHORT_TO_LONG_DAYS[d], true);
                    });
                    setCustomOptionData(null)
                  }}
                  active={recurring}
                >
                  Daily
                </Item>
                <Item
                  onClick={() => {
                    setRepeatType("custom");
                    setRecurring(true)
                    setIsModalOpen(true);
                  }}
                >
                  Custom
                </Item>
              </DropDownContent>
            </Element>
          </DropDown>
        </DropDownWrapper>
      </Wrapper>

      {recurring && repeatType !== 'daily' && (
        <Days>
          {SHORT_DAYS.map(d => (
            <Day
              key={d}
              className={
                selectedDays.has(SHORT_TO_LONG_DAYS[d]) ? "active" : ""
              }
              onClick={() => {
                onChangeDay(
                  SHORT_TO_LONG_DAYS[d],
                  selectedDays.size === 1 ? true : !selectedDays.has(SHORT_TO_LONG_DAYS[d])
                );
              }}
            >
              {d.substring(0, 1)}
            </Day>
          ))}
        </Days>
      )}

      {recurring && <Info>Days selected will be post dated from Send on.</Info>}

      {errors.sendOn && <DaysError>{errors.sendOn}</DaysError>}
      {isModalOpen && (
        <CustomDateModal
          closeModal={() => setIsModalOpen(false)}
          resetDropdownValue={() => setRepeatType("weekly")}
          repeatType={repeatType}
          selectedDays={selectedDays}
          onChangeDay={onChangeDay}
          customOptionData={customOptionData}
          setCustomOptionData={setCustomOptionData}
          setRepeatType={setRepeatType}
          numberOfRepeats={numberOfRepeats}
          setNumberOfRepeats={setNumberOfRepeats}
        />
      )}
    </>
  );
};

export default ScheduleRepeat;

// @flow
import React from "react";
import pluralize from "pluralize";
import { mostStrictImageLimit } from "lib/images/validateImage";

import Icon from "icons/Icon";
import ImageUploader from "./ImageUploader";
import tooltipper from "behavioral/tooltipper";
import imageAttachmentIcon from "components/common/images/image-attachment-icon.svg";
import { onlyOneGIFAllowedForPlatform } from "../../util.js";

import {
  MAX_UPLOADED_IMAGE_BYTES,
  MEGABYTE,
  COMPOSER_MAX_IMAGE_COUNT
} from "constants";
import { capitalize } from "lodash";

import styles from "./index.css";

import type {
  loadContent_content_variations as VariationData,
  Platform,
  Provider
} from "graphql-types/loadContent";

const videoExistsReason =
  "Variations can't have both video and image attachments. Please remove the video to add an image.";

const buildImageLimitExceededReason = ({ platformName, count }) => {
  const pluralizedCount = pluralize("image", count, true);
  const suffix =
    platformName !== "Edgar"
      ? `Remove any ${platformName} accounts from the content if you'd like to add more images.`
      : "";

  return `${platformName} supports only ${pluralizedCount} on a variation. ${suffix}`;
};

// eslint-disable-next-line max-len
const linkPreviewEnabledReason =
  "Variations can't have both image and link preview attachments. Please remove the link preview to add an image.";

const onlyOneGIFReason =
  "Facebook and Twitter allow only one GIF as an attachment.";

const igBusinessMaxAttachmentsReason =
  "Instagram Business accounts support only 10 attachments on a variation (images and videos).";

function getDisabledReason(
  variation,
  platforms,
  providers,
  linkAttached,
  sendMobileReminder,
  instaReels,
  instaStory
) {
  const { images, videos } = variation;
  const imageCountLimit = mostStrictImageLimit(
    platforms,
    providers,
    sendMobileReminder
  );

  if (!providers?.length) {
    return "Please select a provider before adding an image.";
  }

  // If there's only INSTAGRAM_BUSINESS as a provider
  // then the validation is different
  if (providers.length === 1 && (providers[0] === "INSTAGRAM_BUSINESS" || providers[0] === "INSTAGRAM_PROFESSIONAL")) {
    if (instaReels) {
      if (videos.length >= 1) {
        return (
          "Variations for IG Reels can't have more than one video" +
          " attachment. Please remove the video to add a new one."
        );
      }
      if (images.length >= 0) {
        return "Variations for IG Reels can't have image attachments.";
      }
    }

    if (instaStory) {
      if (videos.length >= 1) {
        return (
          "Variations for IG Story can't have more than one video" +
          " attachment. Please remove the video to add a new one."
        );
      }
      if (images.length >= 1) {
        return ("Variations for IG Story can't have more than one Image" +
          " attachment. Please remove the Image to add a new one.")
      }
    }

    if (images.length + videos.length >= 10) {
      return igBusinessMaxAttachmentsReason;
    }
  } else {
    if (images.length >= imageCountLimit.count) {
      return buildImageLimitExceededReason(imageCountLimit);
    } else if (videos.length > 0) {
      return videoExistsReason;
    }

    if (linkAttached) {
      return linkPreviewEnabledReason;
    }

    if (onlyOneGIFAllowedForPlatform(platforms, images)) {
      return onlyOneGIFReason;
    }
  }

  return null;
}

type Props = {
  variation: VariationData,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  sendMobileReminder: boolean,
  instaReels: boolean,
  instaStory: boolean,
  linkAttached: boolean,
  onStart: (variationClientId: string, file: File) => void,
  onProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onFinish: (variationClientId: string, url: string, file: File) => void
};

export const TooltipBody = ({
  variation,
  selectedPlatforms,
  selectedProviders,
  linkAttached,
  sendMobileReminder,
  instaReels,
  instaStory
}: Props) => {
  const disabledReason = getDisabledReason(
    variation,
    selectedPlatforms,
    selectedProviders,
    linkAttached,
    sendMobileReminder,
    instaReels,
    instaStory
  );
  if (disabledReason) {
    return <p>{disabledReason}</p>;
  }

  const limits = Object.keys(COMPOSER_MAX_IMAGE_COUNT).map(platform => ({
    platform,
    limit: COMPOSER_MAX_IMAGE_COUNT[platform]
  }));

  const renderSection = ({ platform, limit }) => {
    const isInstagram = platform.includes("INSTAGRAM");
    const iconType = `${
      isInstagram ? "instagram" : platform.toLowerCase()
    }-square`;
    return (
      <>
      <strong>{(platform.split("_").map(x => capitalize(x)).join(" ") ) }</strong>
      <ul>
        <li>PNG, JPG or GIF extension</li>
        <li>{MAX_UPLOADED_IMAGE_BYTES / MEGABYTE}MB file size</li>
        <li>Supports up to {pluralize("images", limit, true)}</li>
      </ul>
      </>
    );
  }
  return (
    <div className={styles.tooltiperBody} >
      <div className={styles.tooltiperBodyLeft}>
      {limits.slice(0,4).map(renderSection)}
        
      </div>

      <div className={styles.tooltiperBodyRight} >
      {limits.slice(4,9).map(renderSection)}
        
      </div>
       
    </div>      
  );
};


export const Subtitle = () => (
  <span>
    <br />
    (Supported by <Icon type="facebook-square" /> <Icon type="twitter-square" />{" "}
    <Icon type="instagram" /> <Icon type="pinterest" />)
  </span>
);
export const ImageAttachmentButton = ({
  variation,
  selectedPlatforms,
  selectedProviders,
  linkAttached,
  sendMobileReminder,
  instaReels,
  instaStory,
  onStart,
  onProgress,
  onUploadError,
  onValidationError,
  onFinish
}: Props) => {
  const { clientId, images, videos } = variation;
  const inputId = `imageUpload-${clientId}`;
  const disabled = !!getDisabledReason(
    variation,
    selectedPlatforms,
    selectedProviders,
    linkAttached,
    sendMobileReminder,
    instaReels,
    instaStory
  );

  return (
    <>
      <label className={styles.root} htmlFor={!disabled ? inputId : null}>
        <img
          src={imageAttachmentIcon}
          disabled={!!disabled}
          alt="Add photo"
          role="button"
          className={styles.icon}
        />
      </label>
      <ImageUploader
        inputId={inputId}
        sendMobileReminder={sendMobileReminder}
        selectedPlatforms={selectedPlatforms}
        selectedProviders={selectedProviders}
        existingImageCount={images.length}
        existingVideoCount={videos.length}
        onStart={onStart.bind(null, clientId)}
        onFinish={onFinish.bind(null, clientId)}
        onProgress={onProgress.bind(null, clientId)}
        onUploadError={onUploadError.bind(null, clientId)}
        onValidationError={onValidationError.bind(null, clientId)}
      />
    </>
  );
};

export default tooltipper(TooltipBody, { title: "Add photo",
  maxWidth: 514,
  Subtitle })(
  ImageAttachmentButton,
);

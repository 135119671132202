import { useState } from "react";

const useCustomDateModal = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const showDropdown = () => setDropdownVisible(true);
  const hideDropdown = () => setDropdownVisible(false);

  return { dropdownVisible, showDropdown, hideDropdown };
};

export default useCustomDateModal;

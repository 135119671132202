/* @flow */
import React, { Component } from "react";
import moment from "moment";
import invariant from "invariant";
import H4 from "typography/H4";
import Caption from "typography/Caption";
import Button from "components/common/buttons/Button";
import Field from "forms/LegacyField";
import FeedUrl from "components/common/FeedUrl";
import CheckboxInput from "forms/CheckboxInput";
import AccountSelect from "components/common/AccountSelect";
import Modal from "layout/modals/Modal";
import CategorySelect from "components/common/CategorySelect";
import styles from "./index.css";

import type { Account, RssFeedChangeset, BrandedImportCardCopy } from "types";
import type { getRssFeeds_rssFeeds as RssFeed } from "graphql-types/getRssFeeds";
import type { Category } from "graphql-types/category";
import RadioButton2 from "components/common/forms/RadioButton2";

type Props = {
  isNewFeed: boolean,
  rssFeed: RssFeed,
  categories: Category[],
  accounts: Account[],
  loading: boolean,
  error: boolean,
  configConnectionCopy: ?BrandedImportCardCopy,
  vimeoChannelTypeSelection: ?string,
  onDelete: (id: string) => void,
  onCancel: () => void,
  onSubmit: (rssFeed: RssFeedChangeset) => void
};

type State = {
  requiresApproval: boolean,
  categoryId: ?string,
  accountIds: string[],
  useOnce: boolean
};

const DISABLED_PROVIDERS = [
  "INSTAGRAM_PERSONAL",
  "INSTAGRAM_PROFESSIONAL",
  "INSTAGRAM_BUSINESS",
  "PINTEREST"
];

/* we are not allowed to import content from personal FB profiles */
const HIDDEN_PROVIDERS = ["FACEBOOK"];

export default class RssFeedForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.deriveStateFromProps()
    };
  }

  deriveStateFromProps = () => {
    const {
      rssFeed: { category, requiresApproval, accounts, useOnce }
    } = this.props;

    const accountIds: string[] = (accounts || []).map(a => a.id);

    return {
      accountIds,
      categoryId: category?.id,
      requiresApproval: requiresApproval ?? true,
      useOnce: useOnce ?? false
    };
  };

  onChangeCategory = ({ id: categoryId }: Category): void => {
    this.setState({ categoryId });
  };

  onClickToPending = (): void => {
    this.setState({ requiresApproval: true });
  };

  onClickToLibrary = (): void => {
    this.setState({ requiresApproval: false });
  };

  onChangeAccounts = (accountIds: string[]): void => {
    this.setState({ accountIds });
  };

  onChangeUseOnce = (useOnce: boolean): void => {
    this.setState({ useOnce });
  };

  onSubmit: () => void = () => {
    const {
      rssFeed: { id, url, name }
    } = this.props;
    const formState = { ...this.state };

    this.props.onSubmit({
      ...formState,
      id,
      url,
      name
    });
  };

  onDelete: () => void = () => {
    const { id } = this.props.rssFeed;
    invariant(!!id, "id is required to delete");
    this.props.onDelete(id);
  };

  renderFooter() {
    const { isNewFeed, onCancel, loading } = this.props;

    return (
      <div>
        <span className={styles.secondaryActions}>
          {!isNewFeed && (
            <span className={styles.delete}>
              <Button type="secondary-negative" onClick={this.onDelete}>
                Delete feed
              </Button>
            </span>
          )}
          <Button onClick={onCancel} type="secondary" disabled={loading}>
            Cancel
          </Button>
        </span>
        <Button
          type="primary"
          onClick={this.onSubmit}
          disabled={loading || this.state.accountIds.length === 0}
        >
          {isNewFeed ? "Add Connection" : "Configure Connection"}
        </Button>
      </div>
    );
  }

  render() {
    const {
      isNewFeed,
      accounts,
      rssFeed: { url, name, lastImportedAt },
      onCancel
    } = this.props;
    const { categoryId, accountIds, requiresApproval } = this.state;

    return (
      <Modal
        size="legacy"
        title={isNewFeed ? "Add Connection" : "Edit Connection"}
        footer={this.renderFooter()}
        onClose={onCancel}
      >
        <Field>
          {(() => {
            if (this.props.vimeoChannelTypeSelection) {
              return <label>{this.props.vimeoChannelTypeSelection}</label>;
            } else if (this.props.configConnectionCopy) {
              return <label>{this.props.configConnectionCopy.heading}</label>;
            } else {
              return null;
            }
          })()}
          <div className={styles.feedInfo}>
            {name ? <h3>{name}</h3> : null}
            <FeedUrl url={url} />
          </div>
        </Field>

        <div className={styles.colWrapper}>
          <div className={styles.leftCol}>
            <AccountSelect
              disabledProviders={DISABLED_PROVIDERS}
              hiddenProviders={HIDDEN_PROVIDERS}
              displayOneTwitterAccountPerAlert
              displayNoInstagramAlert
              displayNoPinterestAlert
              className={styles.accountSelect}
              accounts={accounts}
              selected={accountIds}
              onChange={this.onChangeAccounts}
              stackOptions
              sortByEnabled
            />
          </div>

          <div className={styles.middleCol} />
          <div className={styles.middleCol} />

          <div className={styles.rightCol}>
            <Field>
              <label>Select category</label>
              <div className={styles.categoryField}>
                <CategorySelect
                  value={categoryId}
                  onChange={this.onChangeCategory}
                  disableAddButton
                />
              </div>
            </Field>

            <Field>
              <H4>Import options</H4>
              {lastImportedAt ? (
                <div className={styles.lastImported}>
                  Last imported at{" "}
                  {moment(lastImportedAt).format("hh:mma on MMMM Do, YYYY")}
                </div>
              ) : null}

              <div className={styles.rssFeedFormRadioButtonWrapper}>
                <RadioButton2
                  className={styles.rssFeedFormRadioButton}
                  selected={requiresApproval}
                  onClick={this.onClickToPending}
                >
                  <span>
                    Import to Pending Content where you can approve or reject
                    updates
                  </span>
                </RadioButton2>
                <RadioButton2
                  className={styles.rssFeedFormRadioButton}
                  selected={!requiresApproval}
                  onClick={this.onClickToLibrary}
                >
                  <span>Import directly to your library</span>
                </RadioButton2>
              </div>
            </Field>

            <div>
              <H4>Advanced options</H4>
              <Field>
                <CheckboxInput
                  value={this.state.useOnce}
                  labelClassName={styles.useOnceLabel}
                  label="Use Once"
                  onChange={this.onChangeUseOnce}
                />
                <Caption className={styles.useOnceCaption} helpText>
                  Posts imported from this feed will only be published one time
                  and then retired. They will not repeat.
                </Caption>
              </Field>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

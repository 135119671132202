/* @flow */
import React, { useEffect } from "react";

import { SCRAPE_STATUSES } from "constants";
import { mostStrictImageLimit } from "lib/images/validateImage";
import { mostStrictVideoLimit } from "lib/videos/validateVideo";
import { mayHaveLinkAttachment } from "../../util.js";
import { Label } from "typography";
import MediaAttacher from "./MediaAttacher";
import LinkPreviewAttachmentToggle from "./LinkPreviewAttachmentToggle";
import MediaAttachments from "./MediaAttachments";
import PinterestSettings from "./PinterestSettings";

import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_pageScrape as PageScrapeData,
  loadContent_content_variations_pageScrape_images as ScrapedImageData,
  Platform,
  Provider
} from "graphql-types/loadContent";

import styles from "./index.css";

export const LINK_ATTACHMENT_PLATFORMS = ["FACEBOOK", "LINKEDIN"];
export const DISALLOW_LINK_ATTACHMENT_PLATFORMS = ["PINTEREST", "INSTAGRAM"];

type MediaUpload = { fileName: string, progress: ?number };
type Props = {
  images: ImageData[],
  imagesTextVariations: ?(any[]),
  videos: VideoData[],
  sendMobileReminder: boolean,
  instaReels: boolean,
  linkAttached: boolean,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  pinterestTitle: ?string,
  pinterestDestinationLink: ?string,
  pageScrape: PageScrapeData,
  uploadingMedia: MediaUpload[],
  allAttachedVideos: VideoData[],
  allAttachedImages: ImageData[],
  attachingImage: boolean,
  clientErrors: { text: string[], fbText: string[] },
  onAttachImage: (variationClientId: string, image: ImageData) => void,
  onAttachVideo: (variationClientId: string, video: VideoData) => void,
  onAttachScrapedImage: (
    variationClientId: string,
    scrapedImage: ScrapedImageData
  ) => void,
  onRemoveImage: (id: string) => void,
  onImagesOrderChange: (images: ImageData[]) => void,
  onImagesTitleChange: (images: ImageData[]) => void,
  onRemoveVideo: (id: string) => void,
  onEnableLinkPreview: () => void,
  onDisableLinkPreview: () => void,
  onChangePinterestDestinationLink: (link: string) => void,
  onChangePinterestTitle: (title: string) => void,
  setOnDragEndMethod: any,
  variationId: string,
  onImageUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void
};

export const Attachments = ({
  images,
  imagesTextVariations,
  videos,
  sendMobileReminder,
  instaReels,
  linkAttached,
  pageScrape,
  selectedPlatforms,
  selectedProviders,
  pinterestTitle,
  pinterestDestinationLink,
  uploadingMedia,
  allAttachedVideos,
  allAttachedImages,
  attachingImage,
  clientErrors,
  onAttachImage,
  onAttachVideo,
  onAttachScrapedImage,
  onRemoveImage,
  onRemoveVideo,
  onImagesOrderChange,
  onImagesTitleChange,
  onEnableLinkPreview,
  onDisableLinkPreview,
  onChangePinterestDestinationLink,
  onChangePinterestTitle,
  setOnDragEndMethod,
  variationId,
  onImageUploadValidationError,
  linkedinCarousel,
  instaStory
}: Props) => {
  const imageCountLimit = mostStrictImageLimit(
    selectedPlatforms,
    selectedProviders,
    sendMobileReminder
  );
  const videoCountLimit = mostStrictVideoLimit(selectedPlatforms);
  const atMediaCountLimit =
    (imageCountLimit.platformName != "TikTok" &&
      imageCountLimit.count - images.length <= 0) ||
    videoCountLimit.count - videos.length <= 0;

  const showLinkedInCarouselProps =
    selectedProviders.includes("LINKEDIN_COMPANY") && linkedinCarousel;
  const hasPinterestAccount = selectedPlatforms.includes("PINTEREST");
  const hasTiktokAccount = selectedPlatforms.includes("TIKTOK");
  const unattachedExistingImages = (allAttachedImages ?? []).filter(
    attachedImg =>
      !images.some(
        img => img.clientProvidedSha256 === attachedImg.clientProvidedSha256
      )
  );
  const unattachedExistingVideos = (allAttachedVideos ?? []).filter(
    attachedVid =>
      !videos.some(
        vid => vid.clientProvidedSha256 === attachedVid.clientProvidedSha256
      )
  );
  const unattachedScrapedImages = (pageScrape?.images ?? []).filter(
    scrapedImg =>
      !images.some(img => img.clientProvidedSha256 === scrapedImg.sha256) &&
      !unattachedExistingImages.some(
        img => img.clientProvidedSha256 === scrapedImg.sha256
      )
  );
  const hasAttachedMedia =
    images.length > 0 || videos.length > 0 || uploadingMedia.length > 0;

  const mediaAttacherVisible =
    unattachedScrapedImages.length > 0 ||
    unattachedExistingVideos.length > 0 ||
    unattachedExistingImages.length > 0;

  let handleOnDragEndMethod = () => { };

  useEffect(() => {
    setOnDragEndMethod(handleOnDragEndMethod);
  }, [setOnDragEndMethod]);

  const setHandleOnDragEndMethod = newMethod => {
    handleOnDragEndMethod = newMethod;
  };

  let disabledErrorMessage = "";

  if (hasTiktokAccount) {
    disabledErrorMessage = "Tiktok does't support for image.";
  } else if (hasPinterestAccount) {
    disabledErrorMessage =
      "To comply with Pinterest’s duplicate pin rules, an image or video may only be attached to one variation. Please select a different attachment for this variation.";
  }

  return (
    <div className={styles.root}>
      {(hasAttachedMedia || hasPinterestAccount || linkAttached) && (
        <Label className={styles.attachmentsLabel}>Attachments</Label>
      )}
      {hasPinterestAccount && (
        <PinterestSettings
          title={pinterestTitle}
          link={pinterestDestinationLink ?? pageScrape?.url}
          errors={clientErrors}
          onChangeLink={onChangePinterestDestinationLink}
          onChangeTitle={onChangePinterestTitle}
        />
      )}
      <MediaAttachments
        setOnDragEndMethod={setHandleOnDragEndMethod}
        showLinkedInCarouselProps={showLinkedInCarouselProps}
        visible={hasAttachedMedia}
        sendMobileReminder={sendMobileReminder}
        instaReels={instaReels}
        instaStory={instaStory}
        imagesTextVariations={imagesTextVariations}
        images={images}
        videos={videos}
        pendingMedia={uploadingMedia}
        selectedPlatforms={selectedPlatforms}
        selectedProviders={selectedProviders}
        onRemoveImage={onRemoveImage}
        onRemoveVideo={onRemoveVideo}
        onImagesOrderChange={onImagesOrderChange}
        onImagesTitleChange={onImagesTitleChange}
        variationId={variationId}
        onAttachImage={onAttachImage}
        onImageUploadValidationError={onImageUploadValidationError}
      />
      <MediaAttacher
        allAttachedVideos={allAttachedVideos}
        allAttachedImages={allAttachedImages}
        videos={unattachedExistingVideos}
        images={unattachedExistingImages}
        scrapedImages={unattachedScrapedImages}
        loadingScrape={pageScrape?.status === SCRAPE_STATUSES.WAITING}
        pageScrapeUrl={pageScrape?.url}
        atMediaCountLimit={atMediaCountLimit}
        visible={mediaAttacherVisible}
        hasImageAttachment={images.length > 0}
        hasVideoAttachment={videos.length > 0}
        hasLinkPreviewAttachment={linkAttached}
        attachingImage={attachingImage}
        onAttachImage={onAttachImage}
        onAttachVideo={onAttachVideo}
        onAttachScrapedImage={onAttachScrapedImage}
        disableDuplicateMedia={hasPinterestAccount}
        disabledMessage={disabledErrorMessage}
      />
      {mayHaveLinkAttachment(selectedPlatforms, pageScrape) && (
        <LinkPreviewAttachmentToggle
          linkAttached={linkAttached}
          pageScrape={pageScrape}
          selectedPlatforms={selectedPlatforms}
          hasAttachedMedia={hasAttachedMedia}
          onEnableLinkPreview={onEnableLinkPreview}
          onDisableLinkPreview={onDisableLinkPreview}
        />
      )}
    </div>
  );
};

export default Attachments;

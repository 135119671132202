import React from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { createFlash } from "actions/flash";
import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";

import styles from "./index.css";

import UPGRADE_SUBSCRIPTION from "mutations/upgradeSubscription";

import GET_USER_DETAILS from "queries/getUserDetails";

import Button from "buttons/Button";
import Modal from "layout/modals/Modal";
import InfoICON from "components/common/images/information-icon.svg";

import useModalAddAccounts from "./use";
import ExtraPriceForPLan from "./extra_account_price";
import {
  Title,
  Wrapper,
  Info,
  GrayBox,
  HowMany,
  Form,
  FormText,
  Buttons,
  SecondButton
} from "./styles";

type Props = {
  close: () => void,
  flash: (type: FlashType, body: string) => void
};

const ModalAddAccounts = ({ close, flash }: Props) => {
  const { data } = useQuery(GET_USER_DETAILS);

  const [updateData, { loading }] = useMutation(UPGRADE_SUBSCRIPTION, {
    refetchQueries: [{ query: GET_USER_DETAILS }],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  const { total, handleChange } = useModalAddAccounts();
  const { additionalPrice } = ExtraPriceForPLan();
  const subscriptionPlan = data?.user?.company?.subscriptionPlan;

  const month_or_year =
    additionalPrice === 2.99 || additionalPrice === 4.99 ? "month" : "year";

  return (
    <Modal size="large" onClose={close} className={styles.add_accounts_modal}>
      <Wrapper>
        <form
          onSubmit={e => {
            e.preventDefault();
            const promise = updateData({
              variables: {
                totalAccount: parseInt(total)
              }
            });
            if (promise) {
              promise.then(
                (response): void => {
                  const errors =
                    response?.data?.upgradeSubscription?.errors || [];
                  if (errors.length > 0) {
                    flash("error", errors[0]);
                    close();
                  } else {
                    flash(
                      "notice",
                      "You have updated your accounts limit successfully!"
                    );
                    close();
                    // window.location.href = "/settings#account_limit_updated";
                    // location.reload();
                  }
                }
              );
            }
          }}
        >
          <Title>
            You are currently using {data?.user?.company?.totalAccounts} of{" "}
            {subscriptionPlan?.accountLimit +
              data?.user?.company?.additionalAccounts}{" "}
            accounts
          </Title>
          <Info>
            Additional accounts can be added for ${additionalPrice} a{" "}
            {month_or_year}.
            <img src={InfoICON} />
          </Info>
          <div id="errors" />

          <GrayBox>
            <HowMany>
              How many additional accounts would you like to add?
            </HowMany>
            <HowMany>
              Currently you have added {data?.user?.company?.additionalAccounts}{" "}
              social accounts
            </HowMany>
            <Form>
              <input
                type="number"
                step={1}
                onChange={handleChange}
                value={total}
              />
              <FormText>
                @&nbsp; ${additionalPrice} /{" "}
                {month_or_year === "month" ? "mo" : "yr"}. &nbsp;=&nbsp;
                <span>
                  {(total * additionalPrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD"
                  })}
                  / {month_or_year === "month" ? "mo" : "yr"}.
                </span>
              </FormText>
            </Form>
          </GrayBox>

          <Buttons className={styles.socialAccModal}>
            <div>
              <Button type="light" block onClick={close}>
                Cancel
              </Button>
            </div>
            <SecondButton>
              <Button
                className={styles.button}
                submit
                type="primary"
                loading={loading}
              >
                Add {total} Accounts
              </Button>
            </SecondButton>
          </Buttons>
        </form>
      </Wrapper>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ModalAddAccounts);

/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Link from "links/Link";
import Select from "forms/Select";

import styles from "./index.css";

import type { FlashType } from "reducers/flash";
import type { getUserSettings_user_company_rebrandlyDomains as RebrandlyDomain } from "graphql-types/getUserSettings";
import type {
  updateDefaultLinkShortenerDomain as UpdateDefaultLinkShortenerDomainResult,
  updateDefaultLinkShortenerDomainVariables
} from "graphql-types/updateDefaultLinkShortenerDomain";

type Option = {
  label: string,
  value: string
};

type State = {
  isModalOpen: boolean,
  selectedDomainId: string
};

type Props = {
  defaultIsModalOpen: boolean,
  defaultLinkShortenerDomainId?: string,
  flash: (type: FlashType, body: string) => void,
  rebrandlyDomains?: RebrandlyDomain[],
  updateDefaultLinkShortenerDomain?: ({
    variables: updateDefaultLinkShortenerDomainVariables
  }) => Promise<{ data: UpdateDefaultLinkShortenerDomainResult }>,
  updateDefaultLinkShortenerDomainLoading?: boolean
};

const getSelectedRebrandlyDomainOption = (
  options: Option[],
  defaultLinkShortenerDomainId: string
): ?Option => {
  return (
    options.find(option => option.value === defaultLinkShortenerDomainId) ||
    null
  );
};

const LinkShortenerDomain = ({
  defaultIsModalOpen,
  defaultLinkShortenerDomainId = "",
  flash,
  rebrandlyDomains = [],
  updateDefaultLinkShortenerDomain,
  updateDefaultLinkShortenerDomainLoading
}: Props) => {
  const [state, updateState] = useState<State>({
    isModalOpen: defaultIsModalOpen,
    selectedDomainId: defaultLinkShortenerDomainId
  });
  const closeModal = _evt => {
    updateState({ ...state, isModalOpen: false });
  };
  const domainOptions = rebrandlyDomains.map(domain => {
    return {
      label: domain.fullName,
      value: domain.id
    };
  });
  const currentDomainOption = getSelectedRebrandlyDomainOption(
    domainOptions,
    defaultLinkShortenerDomainId
  );
  const selectedDomainOption = getSelectedRebrandlyDomainOption(
    domainOptions,
    state.selectedDomainId
  );
  return (
    <>
      {state.isModalOpen && (
        <Modal
          footer={
            <>
              <Button
                className={styles.buttonCancel}
                onClick={closeModal}
                type="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={updateDefaultLinkShortenerDomainLoading}
                onClick={(): void => {
                  const promise = updateDefaultLinkShortenerDomain?.({
                    variables: { domainId: state.selectedDomainId }
                  });
                  if (promise) {
                    promise.then(response => {
                      const errors =
                        response?.data?.updateDefaultLinkShortenerDomain
                          ?.errors || [];
                      if (errors.length > 0) {
                        flash("error", errors[0]);
                      } else {
                        closeModal();
                        flash(
                          "notice",
                          "The Rebrand.ly default domain has been successfully updated!"
                        );
                      }
                    });
                  }
                }}
                type="primary"
              >
                Apply
              </Button>
            </>
          }
          onClose={closeModal}
          title="Select the Rebrandly domain you'd like to use"
        >
          <div className={styles.main}>
            <Select
              options={domainOptions}
              value={selectedDomainOption}
              onChange={selected => {
                updateState({ ...state, selectedDomainId: selected.value });
              }}
            />
          </div>
        </Modal>
      )}
      <span className={styles.rebrandlyDomain}>
        {(currentDomainOption && currentDomainOption.label) || "None"} (
        <Link
          onClick={(): void => {
            updateState({ ...state, isModalOpen: true });
          }}
        >
          Change
        </Link>
        )
      </span>
    </>
  );
};

export default LinkShortenerDomain;
